import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ArrowRight from "./../assets/images/ar.png";
import ArrowLeft from "./../assets/images/al.png";
// import CartIcon from "./../assets/images/cart.png";
import HomeService from './../services/HomeService';
import AuthenticationService from "../services/AuthenticationService";
import { ShowPreloader, HidePreloader, SetDefaultLanguage, ShowNotifications, GetLocalizationString } from './../Helpers/CustomHelper';
import default_prd_icon from "./../assets/images/icon/dft_category_icon.png";
import default_user_img from "./../assets/images/default_user_img.png"

class MyCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProductsList: [],
      cart_items: [],
      full_name: '',
      phone_no: '',
      address: '',
      remark: '',
      errors: {},
      isSubmit: false,
      user_data: {},
      cartDetails: [],
      alreadyAdded: false,
      changed: false
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.createOrderPayload = this.createOrderPayload.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    ShowPreloader();
    // Fetch user profile data
    this.getUserProfile();
    HomeService.getCartItems()
      .then(response => {
        HidePreloader();
        console.log('Cart items:', response);
        if ((global.successStatus).includes(response.status)) {
          if( response.data.length === 0){
            this.props.history.push({
                pathname: '/home'                            
            });
        }
          const cartDetails = response.data.cart_items;
          const cartItems = cartDetails.cart_items ? cartDetails.cart_items : [];
          this.setState({
            ProductsList: cartItems,
            store_id: cartDetails.store_id,
            cartDetails: cartDetails,
            cart_items: cartItems.map(item => ({
              slug: item.slug,
              product_id: item.product_id,
              quantity: item.quantity,
            })),
          });
          localStorage.setItem('cart_items', JSON.stringify(cartItems));  // Store in localStorage for persistence
        } else {
          // ShowNotifications('error', GetLocalizationString('not_found'), 1500);
        }
      })
      .catch(error => {
        HidePreloader();
        console.error('Error fetching cart items:', error);
        // ShowNotifications('error', GetLocalizationString('not_found'), 1500);
      });
  }

  getUserProfile() {
    AuthenticationService.getProfile()
      .then((res) => {
        if ((global.successStatus).includes(res.status)) {
          SetDefaultLanguage(res.data.selected_language)
          console.log('Profile data:', res.data);
          this.setState({
            user_data: res.data,
            default_language: res.data.default_language,
            badge_count: res.data.badge_count,
            full_name: res.data.full_name,
            phone_no: res.data.mobile,
            address: res.data.address
          })
        } else {
          HidePreloader();
          console.error('Error fetching profile data:', res.status);
          ShowNotifications('error', GetLocalizationString('not_found'), 1500);
        }
      }).catch((errors) => {
        HidePreloader();
        console.error('Error fetching profile data:', errors);
        ShowNotifications('error', GetLocalizationString('not_found'), 1500);
      })
  }
  // Handle input change and update state
  changeHandler(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  // Function to validate form inputs
  validateForm() {
    const { full_name, phone_no, address } = this.state;
    let errors = {};
    let formIsValid = true;

    if (!full_name) {
      formIsValid = false;
      errors['full_name'] = 'Full Name is required';
    }
    if (!phone_no) {
      formIsValid = false;
      errors['phone_no'] = 'Phone Number is required';
    } else if (!/^\d{10}$/.test(phone_no)) {
      formIsValid = false;
      errors['phone_no'] = 'Phone Number must be 10 digits';
    }
    if (!address) {
      formIsValid = false;
      errors['address'] = 'Address is required';
    }

    this.setState({ errors });
    return formIsValid;
  }

  // Function to create order payload
  createOrderPayload() {
    const { address, remark, cart_items } = this.state;
    const phone_no = this.state.user_data.mobile;
    const full_name = this.state.user_data.full_name;
    const store_id = this.state.store_id;
    const payload = {
      full_name,
      phone_no,
      address,
      remark,
      store_id,
      cart_items
    };

    return payload;
  }

  // Handle form submission and call server API
  handleSubmit(e) {
    e.preventDefault();
    ShowPreloader();

    if (this.validateForm()) {
      this.setState({ isSubmit: true });
      this.AddToCartItems();
      const orderPayload = this.createOrderPayload();

      HomeService.PlaceOrder(orderPayload)
        .then((res) => {
          HidePreloader();
          localStorage.removeItem('cart_items');
          if ((global.successStatus).includes(res.status)) {
            console.log(res);
            this.props.history.push({
              pathname: '/order-confirmation',
            });
          } else {
            if (res.status === 422) {
              this.setState({ errors: res.data.result.errorDetail });
              HidePreloader();
            }
            console.log(res, "sadadasdadasdasdadasdasdadasdsa");
            ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
          }
        })
        .catch((errors) => {
          HidePreloader();
          console.error(errors);
          ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
        });
    } else {
      HidePreloader();
      ShowNotifications('error', GetLocalizationString('validation_error'), 1500);
    }
  }

  deleteProduct = (cart_id) => {
    ShowPreloader();
    if(window.confirm(GetLocalizationString('delete_cart_item_message')) == true) {    
    HomeService.DeleteCartItems(cart_id)
      .then(response => {
        HidePreloader();
        console.log('Cart item deleted:', response);
        if ((global.successStatus).includes(response.status)) {
          ShowNotifications('success', GetLocalizationString('item_removed'), 1500);
          localStorage.removeItem('cart_items');
          this.componentDidMount();
        } else {
          ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
          HidePreloader();
        }
      })
      .catch(error => {
        HidePreloader();
        console.error('Error deleting cart item:', error);
        ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
      });
    }else{
      HidePreloader();
      return false;
    }
  }

  AddToCartItems = () => {
    // Prepare the payload for the API
    this.setState({ changed: false });
    console.log(this.state.cart_items,"cart items");
    const payload = {
      store_id: this.state.store_id,
      cart_items: this.state.cart_items.map(item => ({
        slug: item.slug,
        quantity: item.quantity,
        product_id: item.product_id
      }))
    };
    // Call the addToCart function from HomeService
    HomeService.addToCart(payload)
      .then(response => {
        HidePreloader(); // Hide preloader after response
        console.log('Add to cart response:', response);
        // Check if the API call was successful
        if ((global.successStatus).includes(response.status)) {
          console.log('Add to cart response:', response);
        } else {
          ShowNotifications('error', GetLocalizationString('error_adding_to_cart'), 1500);
        }
      })
      .catch(error => {
        HidePreloader(); // Hide preloader in case of error
        console.error('Error adding to cart:', error);
        // ShowNotifications('error', GetLocalizationString('error_adding_to_cart'), 1500);
      });
  };

  manageCartItems = (event, product) => {
    const { name } = event.target;  // name can be 'plus' or 'minus'

    // Clone the current ProductsList
    let updatedProductsList = [...this.state.ProductsList];
    console.log('updatedProductsList:', updatedProductsList);
    // Find the product in the ProductsList by product_id
    const productIndex = updatedProductsList.findIndex(item => item.product_id === product.product_id);

    if (productIndex !== -1) {
      // Update the quantity based on the clicked button (plus or minus)
      if (name === 'plus') {
        updatedProductsList[productIndex].quantity += 1;
      } else if (name === 'minus' && updatedProductsList[productIndex].quantity > 0) {
        updatedProductsList[productIndex].quantity -= 1;
      }
      // Now manage only this specific product's entry in the cart_items
      let updatedCartItems = [...this.state.cart_items];
      const cartItemIndex = updatedCartItems.findIndex(item => item.product_id === product.product_id);

      const updatedCartItem = {
        slug: updatedProductsList[productIndex].slug,
        product_id: updatedProductsList[productIndex].product_id,
        quantity: updatedProductsList[productIndex].quantity,
        product_image: updatedProductsList[productIndex].product.product_image,
        product_name: updatedProductsList[productIndex].product.product_name,
        price: updatedProductsList[productIndex].product.price,
        unit_type: updatedProductsList[productIndex].product.unit_type
      };

      if (cartItemIndex !== -1) {
        // If the product is already in cart_items, update it
        updatedCartItems[cartItemIndex] = updatedCartItem;
      } else {
        // If it's not in cart_items, add it
        updatedCartItems.push(updatedCartItem);
      }

      // Update the state with the new ProductsList and updatedCartItems
      this.setState({
        ProductsList: updatedProductsList,
        cart_items: updatedCartItems,
        changed: true,
        errors: {}
      }, () => {
        // Optionally, create the payload here if needed
        this.createCartPayload();
      });
    }
  };

  createCartPayload = () => {
    const payload = {
      cart_items: this.state.cart_items.map(item => ({
        slug: item.slug,
        product_id: item.product_id,
        quantity: item.quantity,
        product_image: item.product_image,
        product_name: item.product_name,
        price: item.price,
        unit_type: item.unit_type
      }))
    };

    console.log("Cart Payload:", payload);

    // Example: You can make an API call to update the cart in the backend
    // axios.post('/api/cart', payload)
    //   .then(response => console.log(response.data))
    //   .catch(error => console.error(error));
  };

  render() {
    const { ProductsList, full_name, phone_no, address, remark, errors } = this.state;
    return (
      <>
        <header>
          <div className="container">
            <div className="row">
              <div className="col-2">
                <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
              </div>
              <div className="col-8">
                <h1 className="head_heading">{GetLocalizationString('checkout')}</h1>
              </div>
              <div className="col-2 text-right">
                {/* <Link
                  to={{
                    pathname: '/my-cart-items/' + this.props.match.params.slug,
                    category_info: this.props.history.location.category_info ?? {},
                    parent_category_id: this.props.history.location.category_info ? this.props.history.location.category_info.level1_service_category_id : 0
                  }}>
                  <img src={CartIcon} alt="" />
                </Link> */}
              </div>
            </div>
          </div>
        </header>
        {ProductsList.length === 0 ? (
          <>
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="success_box no_cnt_data">
                      <h5 className="app_done">{GetLocalizationString('no_items_in_cart')}</h5>
                      <Link onClick={() => {
                        this.props.history.push({
                          pathname: '/home'
                        });
                      }} className="btn btn-theme mb-5">{GetLocalizationString('go_to_home')} <img src={ArrowRight} alt="" /></Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )
          :
          (
            <section>
              <div className="container">
                    {errors.length > 0 &&
                      (
                        <div className="alert alert-danger" role="alert">
                        <ul>
                          {errors.map((error, i) => (
                            <li key={i}>
                              <p className='text-danger'>{error.errorMessage}</p>
                            </li>
                          ))}
                        </ul>
                        </div>
                      )}
                <div className="row">

                  <>
                    <div className="user_book_info user_detail box_sd_none mt-0 p-0" >
                      <div className="media" >
                        <img className="d-flex" src={this.state.cartDetails.store_detail?.category_image ?? default_user_img} alt="" />
                        <div className="media-body">
                          <h5 className="p-0 m-3">{this.state.cartDetails.store_detail.service_category_title}  <span className='service_title'>{"Seller"}</span></h5>
                        </div>
                      </div>
                    </div>
                    {console.log('ProductsList:', ProductsList)}
                    {ProductsList.map((cartDetails, i) => (
                      <div className="col-12 mb-3 doctor_details" key={"maindiv" + cartDetails.cart_item_id}>
                        <>
                          <div className="mb-1" key={"details" + cartDetails.cart_item_id}>
                            <div className="row g-0 align-items-center ">
                              {/* Product Image */}
                              <div className="col-md-2 text-center">
                                <img
                                  src={cartDetails.product.product_image || default_prd_icon}
                                  alt={cartDetails.product.product_name}
                                  className="img-fluid img-listing"
                                />
                              </div>

                              {/* Product Details */}
                              <div className="col-md-10" style={{ borderLeft: "1px solid #D1D1D1", paddingLeft: "10px" }}>
                                <button
                                  className="btn btn-danger btn-sm d-flex align-items-center justify-content-center rounded-circle  float-right"
                                  onClick={() => this.deleteProduct(cartDetails.slug)}
                                  style={{ width: "24px", height: "24px" }}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                                <div className="card-body product-details1">
                                  <h2 className="card-title m-0" style={{ color: "#396EA0" }}>{cartDetails.product.product_name}</h2>
                                  <p className='text-muted m-0'><strong className='text-color'>₹{cartDetails.product.price}</strong> (per {cartDetails.product.unit_type})</p>
                                  {/* Quantity and Controls */}
                                  <div className="row">
                                    <div className="col-md-6">

                                      <div className="product-controls mt-2 col-md-4 d-flex align-items-center justify-content-center rounded p-2 me-2" style={{ backgroundColor: "#1383F41A" }}>
                                        {/* <span className="p-2 me-2 text-color"><strong>1{cartDetails.product.unit_type}</strong></span> */}
                                        <div className="d-flex align-items-center">
                                          <button className={"count-btn " + (cartDetails.quantity <= 1 ? " count-btn-disabled" : "")} name="minus" onClick={(event) => this.manageCartItems(event, cartDetails)} disabled={cartDetails.quantity <= 1}>
                                            -
                                          </button>
                                          <span className="px-2">{cartDetails.quantity}</span>
                                          <button className={"count-btn " + (cartDetails.quantity >= cartDetails.available_qty ? " count-btn-disabled" : "")} name="plus" onClick={(event) => this.manageCartItems(event, cartDetails)} disabled={cartDetails.quantity >= cartDetails.product.available_qty}>
                                            +
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <span className="text-muted product-controls mt-2 float-right align-items-center justify-content-center rounded p-2 me-2">{cartDetails.quantity} x {cartDetails.product.price} = <strong className='text-color'>₹{parseFloat(cartDetails.product.price * cartDetails.quantity).toFixed(2)}</strong></span>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                          {/* <p className="text-muted m-0">1 {product.unit_type} x {product.quantity}</p>
                              <p className="card-text m-0"><strong>₹{product.price}</strong> (Per {product.unit_type})</p> */}
                        </>
                        {cartDetails.message && <p className='text-danger'>{cartDetails.message}</p>}
                      </div>
                    ))}

                    <div className="col-12 mb-5">
                    <h3 className="card-text m-0 text-right text-color"><strong>Total: ₹{parseFloat(this.state.cartDetails.total_amount).toFixed(2)}</strong></h3>
                    </div>
                    {/* Form for Customer Details */}
                    <div className="col-12">
                      <div className="form-group">
                        <label>Full Name*</label>
                        <input
                          type="text"
                          name="full_name"
                          className={`form-control ${errors.full_name ? 'is-invalid' : ''}`}
                          value={full_name}
                          // onChange={this.changeHandler}
                          disabled={true}
                        />
                        {errors.full_name && <div className="invalid-feedback">{errors.full_name}</div>}
                      </div>

                      <div className="form-group">
                        <label>Phone Number*</label>
                        <input
                          type="text"
                          name="phone_no"
                          className={`form-control ${errors.phone_no ? 'is-invalid' : ''}`}
                          value={phone_no}
                          // onChange={this.changeHandler}
                          disabled={true}
                        />
                        {errors.phone_no && <div className="invalid-feedback">{errors.phone_no}</div>}
                      </div>

                      <div className="form-group">
                        <label>Address*</label>
                        <input
                          type="text"
                          name="address"
                          className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                          value={address}
                          onChange={this.changeHandler}
                        />
                        {errors.address && <div className="invalid-feedback">{errors.address}</div>}
                      </div>

                      <div className="form-group">
                        <label>Remarks</label>
                        <textarea
                          name="remark"
                          className={`form-control ${errors.remark ? 'is-invalid' : ''}`}
                          value={remark}
                          onChange={this.changeHandler}
                        />
                        {errors.remark && <div className="invalid-feedback">{errors.remark}</div>}
                      </div>
                    </div>

                    {/* Place Order Button */}
                    <div className="col-12 text-center mt-5 mb-5">
                      <button className="btn btn-theme btn-book_suss" onClick={this.handleSubmit}>
                        {GetLocalizationString('place_order')} <img src={ArrowRight} alt="" />
                      </button>
                    </div>
                  </>

                </div>
              </div>
            </section>
          )}

      </>
    );
  }
}

export default MyCart;
