import React, { Component } from 'react';
import HomeService from "./../services/HomeService";
import { ShowPreloader, HidePreloader, GetLocalizationString, SetDefaultLanguage, ShowNotifications } from './../Helpers/CustomHelper';
import ArrowRignt from "./../assets/images/ar.png";
import ArrowLeft from "./../assets/images/al.png";
import CartIcon from "./../assets/images/cart.png";
import default_prd_icon from "./../assets/images/icon/dft_category_icon.png";
import SearchImg from "./../assets/images/se.png"

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: true,
            ProductsList: [],
            search: '',
            per_page: process.env.REACT_APP_PER_PAGE,
            page: 1,
            category_detail: this.props.history.location.category_info || JSON.parse(localStorage.getItem('category_info_for_product')) || {},
            cart_items: [],
            cart_items_count: 0,
            store_id: 0,
            cart_store_id: 0,
            cart_details:[],
            product_counts: 0,
            parentId: this.props.history.location.parentId || localStorage.getItem('parentId') || 0,
        };

        this.changeHandler = this.changeHandler.bind(this);
        this.manageCartItems = this.manageCartItems.bind(this);
        this.handleScroll = this.debounce(this.handleScroll.bind(this), 200);  // Debounce the scroll handler
    }

    componentDidMount() {
        ShowPreloader();
        SetDefaultLanguage();
        this.setSearch();        
        // Store category_info in localStorage if available in props
        if (this.props.history.location.parentId) {
            localStorage.setItem('parentId', this.props.history.location.parentId);
        }
        // Store category_info in localStorage if available in props
        if (this.props.history.location.category_info) {
            localStorage.setItem('category_info_for_product', JSON.stringify(this.props.history.location.category_info));
        }
        // Load product list
        HidePreloader();
        this.getCartItems();
        // this.getProductsList();
        window.addEventListener("scroll", this.handleScroll); /** attaching scroll event listener */      

    }

    setSearch = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        await this.setState({search:urlParams.get('search') || ''})
        this.getProductsList();
    }

    getCartItems = () => {
        HomeService.getCartItems()
            .then(response => {
                HidePreloader();
                if ((global.successStatus).includes(response.status)) {                    
                    const cartItemsCount = response.data.cart_items.total_items ?? 0;                    
                    this.setState({
                        cart_store_id: response.data.cart_items?.store_id,                        
                        cart_items_count: cartItemsCount,
                        cart_details: response.data.cart_items,                        
                    });
                } else {
                    // ShowNotifications('error', GetLocalizationString('not_found'), 1500);
                }
            })
            .catch(error => {
                HidePreloader();
                // console.error('Error fetching cart items:', error);
                // ShowNotifications('error', GetLocalizationString('not_found'), 1500);
            });
    }

    RedirectToCart = () => {
        this.props.history.push({
            pathname: '/my-cart',
        });
    }

    getProductsList = () => {
        ShowPreloader();
        const { category_detail } = this.state;
        this.setState({previous_search: this.state.search});
        var parentId = this.state.search != "" ? this.state.parentId : category_detail.level2_service_category_id;
        if(this.state.search != "" && this.state.search !== this.state.previous_search){
            this.setState({ProductsList:[]});
        }
        let params = {
            level2_service_category_id: parentId,
            seller: 0,
            level: 2,
            search: this.state.search,
            "per_page": this.state.per_page,
            "page": this.state.page,
        };
        HomeService.getProductsList(params)
            .then((res) => {
                console.log(res, "res");
                if ((global.successStatus).includes(res.status)) {
                    HidePreloader();
                    // Loop to add quantity 1 to all products
                    console.log(res.data.products);
                    // const updatedProducts = res.data.products.map(product => ({
                    //     ...product,
                    //     quantity: 0
                    // }));
                    this.setState({
                        // ProductsList: updatedProducts,
                        ProductsList: [...this.state.ProductsList,...res.data.products],
                        product_counts: res.data.product_counts,
                        isLoaded: true,
                        loading: false
                    });
                } else {
                    HidePreloader();
                    console.log(res);
                }
            }).catch((errors) => {
                HidePreloader();
                console.error(errors);
            });
    }

    AddToCartItems = async () => {
        // Prepare the payload for the API
        var confirm = true;

        if(this.state.cart_store_id !== 0 && this.state.cart_store_id !== this.state.store_id){
            confirm = window.confirm(GetLocalizationString('cart_store_change').replace(":store_name", this.state.cart_details.store_detail.service_category_title));
            if(confirm){
                await this.deleteCart(this.state.cart_details.slug);
            } else {
                return;
            }
        }
        const payload = {
            store_id: this.state.store_id,
            cart_items: this.state.cart_items.map(item => ({
                slug: item.slug,
                quantity: item.quantity,
                product_id: item.product_id
            }))
        };
        // Show preloader before API call
        ShowPreloader();

        // Call the addToCart function from HomeService
        HomeService.addToCart(payload)
            .then(response => {
                HidePreloader(); // Hide preloader after response
                console.log('Add to cart response:', response);
                // Check if the API call was successful
                if ((global.successStatus).includes(response.status)) {
                    // if (response.data && response.data.status === 200) {
                    // If successful, navigate to the checkout page with cart_items
                    this.props.history.push({
                        pathname: '/my-cart',
                        cart_items: this.state.cart_items
                    });
                } else {
                    // Handle any error response from the API
                    ShowNotifications('error', GetLocalizationString('error_adding_to_cart'), 1500);
                }
            })
            .catch(error => {
                HidePreloader(); // Hide preloader in case of error
                console.error('Error adding to cart:', error);
                ShowNotifications('error', GetLocalizationString('error_adding_to_cart'), 1500);
            });        
        
    };


    manageCartItems = (event, product) => {
        const { name } = event.target;  // name can be 'plus' or 'minus'

        // Clone the current ProductsList
        let updatedProductsList = [...this.state.ProductsList];
        // Find the product in the ProductsList by product_id
        this.setState({ store_id: product.store_id });
        const productIndex = updatedProductsList.findIndex(item => item.product_id === product.product_id);

        if (productIndex !== -1) {
            // Update the quantity based on the clicked button (plus or minus)
            if (name === 'plus') {
                updatedProductsList[productIndex].quantity += 1;
            } else if (name === 'minus' && updatedProductsList[productIndex].quantity > 0) {
                updatedProductsList[productIndex].quantity -= 1;
            }

            // Update the total_price based on new quantity
            updatedProductsList[productIndex].total_price = (updatedProductsList[productIndex].quantity * updatedProductsList[productIndex].price).toFixed(2);

            // Now manage only this specific product's entry in the cart_items
            let updatedCartItems = [...this.state.cart_items];
            const cartItemIndex = updatedCartItems.findIndex(item => item.product_id === product.product_id);

            const updatedCartItem = {
                product_id: updatedProductsList[productIndex].product_id,
                quantity: updatedProductsList[productIndex].quantity,
                product_image: updatedProductsList[productIndex].product_image,
                product_name: updatedProductsList[productIndex].product_name,
                price: updatedProductsList[productIndex].price,
                unit_type: updatedProductsList[productIndex].unit_type
            };

            if (cartItemIndex !== -1) {
                // If the product is already in cart_items, update it
                updatedCartItems[cartItemIndex] = updatedCartItem;
            } else {
                // If it's not in cart_items, add it
                updatedCartItems.push(updatedCartItem);
            }

            // Update the state with the new ProductsList and updatedCartItems
            this.setState({
                ProductsList: updatedProductsList,
                cart_items: updatedCartItems
            }, () => {
                // Optionally, create the payload here if needed
                this.createCartPayload();
            });
        }
    };

    createCartPayload = () => {
        const payload = {
            cart_items: this.state.cart_items.map(item => ({
                product_id: item.product_id,
                quantity: item.quantity,
                product_image: item.product_image,
                product_name: item.product_name,
                price: item.price,
                unit_type: item.unit_type
            }))
        };

        console.log("Cart Payload:", payload);

        // Example: You can make an API call to update the cart in the backend
        // axios.post('/api/cart', payload)
        //   .then(response => console.log(response.data))
        //   .catch(error => console.error(error));
    };

    changeHandler = (event) => {
        const value = event.target.value;
    
        // Update state for search text
        this.setState({ [event.target.name]: value });
    
        // Clear any previous timeout
        clearTimeout(this.typingTimeout);
    
        // Set a new timeout for debounce
        this.typingTimeout = setTimeout(() => {
            // Call the API method after typing stops
            this.getProductsList();
        }, 500); // 500ms debounce delay
    };
    

    deleteCart = async (cart_id) => {
        ShowPreloader();
        await HomeService.DeleteCart(cart_id)
          .then(response => {
            HidePreloader();
            console.log('Cart item deleted:', response);
            if ((global.successStatus).includes(response.status)) {
              ShowNotifications('success', GetLocalizationString('item_removed'), 1500);
              localStorage.removeItem('cart_items');
              this.componentDidMount();
            } else {
              ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
              HidePreloader();
            }
          })
          .catch(error => {
            HidePreloader();
            console.error('Error deleting cart item:', error);
            ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
          });
      }

      componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

      debounce(func, delay) {
        let debounceTimer;
        return function (...args) {
            const context = this;
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(context, args), delay);
        };
    }

      handleScroll() {
        const userScrollHeight = window.innerHeight + window.scrollY;
        const windowBottomHeight = document.documentElement.offsetHeight;
        const totalPages = Math.ceil(this.state.product_counts / this.state.per_page);

        if (userScrollHeight >= windowBottomHeight && !this.state.loading && this.state.page < totalPages) {
            this.setState(
                prevState => ({
                    page: prevState.page + 1,
                    loading: true,
                }),
                () => this.getProductsList(this.state.page)
            );
        }
    }

    render() {
        const { ProductsList, cart_items, category_detail, cart_items_count } = this.state;
        return (
            <>
                <header>
                    <div className="container">
                        <div className="row">                            
                            <div className="col-2">
                                <a onClick={() => { this.props.history.goBack() }}>
                                    <img src={ArrowLeft} alt="" />
                                </a>
                            </div>
                            <div className="col-8">
                                <h1 className="head_heading">{this.state.search != "" ? GetLocalizationString('products') : category_detail.service_category_title}</h1>
                            </div>
                            {
                                cart_items_count > 0 &&
                                <div className="col-2 text-right">
                                    <a onClick={this.RedirectToCart}><img src={CartIcon} alt="" className="bell_icon" />
                                        {(cart_items_count > 0)
                                            ?
                                            <span className="bell_count">{cart_items_count}</span>
                                            :
                                            ''
                                        }

                                    </a>
                                </div>
                            }
                        </div>
                        <div className="input-group mt-3">
                                    <div className="input-group-append">
                                    <button className="btn btn-under_input" type="button">
                                        <img src={SearchImg} alt="" className="img-fluid" />
                                    </button>
                                    </div>
                                    <input type="text" className="form-control new_bdr" placeholder={GetLocalizationString('search')} aria-label="" aria-describedby="basic-addon1" name='search' value={this.state.search} onChange={this.changeHandler}/>                                    
                        </div>
                    </div>
                </header>

                <section key={"product_list"}>
                    <div className="container" key={"container"}>
                        <div className="row" style={{ marginBottom: '9%' }} key={"row"}>
                            {/* {
                                this.state.search !== '' &&
                                (
                                    // <div className="col-12">
                                    //     <p className="special_text">{GetLocalizationString('result_for') +' "' + this.state.search + '"'}</p>
                                    // </div>
                                )
                            } */}
                            <div className="col-12" key="category_detail">
                                {
                                    this.state.search !== '' ?
                                        <p className="special_text">{GetLocalizationString('result_for') +' "' + this.state.search + '"'}</p>
                                    :
                                        <p className="special_text">{"Select " + category_detail.service_category_title}</p>
                                }
                            </div>

                            {ProductsList.length === 0 ?
                                <div className="col-12 mb-3" key="no_product">
                                    <div className="success_box no_cnt_data">
                                        <h5 className='text-center'>{GetLocalizationString('no_product')}</h5>
                                    </div>
                                </div>
                                :
                                ProductsList.map((product, i) => (
                                    <>
                                        <div className='col-12 product-list' key={"PRD" + i} >
                                            <div className="card" key={product.product_id} style={{ borderRadius: '15px', padding: '15px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                                                <div className="row g-0 align-items-end">
                                                    {/* Product Image */}
                                                    <div className="col-md-2 text-center">
                                                        <img
                                                            src={product.product_image || default_prd_icon}
                                                            alt={product.product_name}
                                                            className="img-fluid img-listing"
                                                        />
                                                    </div>

                                                    {/* Product Details */}
                                                    <div className="col-md-8" style={{ borderLeft: "1px solid #D1D1D1", paddingLeft: "10px" }}>
                                                        <div className="card-body product-details">
                                                            <h2 className="card-title m-0" style={{ color: "#396EA0" }}>{product.product_name}</h2>
                                                            <p className="card-text m-0"><strong>₹{product.price}</strong> (Per {product.unit_type})</p>
                                                        </div>

                                                        {/* Quantity and Controls */}
                                                        <div className="product-controls col-md-2 d-flex align-items-center justify-content-center rounded p-2 me-2" style={{ backgroundColor: "#1383F41A" }}>
                                                            {/* <span className="p-2 me-2 text-color"><strong>{product.unit_type}</strong></span> */}
                                                            <div className="d-flex align-items-center">
                                                                <button className={"count-btn " + (product.quantity <= 0 ? " count-btn-disabled" : "")} name="minus" onClick={(event) => this.manageCartItems(event, product)} disabled={product.quantity <= 0}>
                                                                    -
                                                                </button>
                                                                <span className="px-2">{product.quantity}</span>
                                                                <button className={"count-btn " + (product.quantity >= product.available_qty ? " count-btn-disabled" : "")} name="plus" onClick={(event) => this.manageCartItems(event, product)} disabled={product.quantity >= product.available_qty}>
                                                                    +
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        product.quantity > 0 &&
                                                            <span className="text-muted">{product.quantity} x {product.price} = <strong className='text-color'>₹{parseFloat(product.price * product.quantity).toFixed(2)}</strong></span>                                                        
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}                                
                                </div>
                            {cart_items.length > 0 && (
                                <div className="Foot_btn_grievance mb-3">
                                    <a onClick={this.AddToCartItems} className="btn btn-theme mt-5">
                                        {GetLocalizationString('add_to_cart')}
                                        <img src={ArrowRignt} alt="" />
                                    </a>
                                </div>
                            )}
                        
                    </div>
                </section>
            </>
        );
    }
}

export default Products;
