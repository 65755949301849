import React from 'react';
import ArrowLeft from "./../assets/images/al.png";
import ArrowRight from "./../assets/images/ar.png";
import HomeService from '../services/HomeService';
import AuthenticationService from "../services/AuthenticationService";
import { GetLocalizationString, HidePreloader, ShowNotifications, SetDefaultLanguage, ShowPreloader } from './../Helpers/CustomHelper';
import { Link } from 'react-router-dom';
import default_img from "./../assets/images/icon/dft_category_icon.png";

class MyCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user_detail: {},
            categories: [],
            isLoaded: false,
            showModal: false,
            currentProduct: null,
            error: null,
            user_data: {},
        };
    }

    componentDidMount() {
        SetDefaultLanguage();
        ShowPreloader();

        // Fetch user profile data
        this.getUserProfile();

        

    }

    getCategories = () => {
        ShowPreloader();
        const { user_data } = this.state;
        let params = {
            level2_service_category_id: user_data.level2_service_category_id
        };
        HomeService.getCategories(params)
            .then((res) => {
                console.log(res,"res1");
                if ((global.successStatus).includes(res.status)) {
                    HidePreloader();                    
                    this.setState({
                        categories: res.data.shopping_categories,
                        isLoaded: true,
                        loading: false
                    });
                } else {
                    HidePreloader();
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
                }
            }).catch((errors) => {
                HidePreloader();
                console.error(errors);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
            });
    }    

    getUserProfile(){
        AuthenticationService.getProfile()
        .then((res)=> {
            if((global.successStatus).includes(res.status)) {
                SetDefaultLanguage(res.data.selected_language)                
                this.setState({
                    user_data: res.data,
                    default_language: res.data.default_language,
                    badge_count:res.data.badge_count,                    
                })
                localStorage.setItem("userData", res.data ? JSON.stringify(res.data) : {})
                // Fetch products for the current user's mall
                this.getCategories();
            }else{
                console.log(res) 
            }
        }).catch((errors)=>{
            console.error(errors)
        })
      }        

    render() {
        const { categories, isLoaded } = this.state;

        return (
            isLoaded ? (
                <>
                    <header>
                        <div className="container">
                            <div className="row">
                                <div className="col-2">
                                    <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                                </div>
                                <div className="col-8">
                                    <h1 className="head_heading">{GetLocalizationString('my_categories')}</h1>
                                </div>
                            </div>
                        </div>
                    </header>

                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 product-list">
                                    {
                                        categories.length <= 0 ? (
                                            <div className="success_box no_cnt_data">
                                                <h5 className="app_done">{GetLocalizationString('no_categories')}</h5>
                                            </div>
                                        ) : (
                                            categories.map((category) => (
                                                <div className="box_cat box_cat_new" key={category.level2_service_category_id}>
                                                    <Link                                                         
                                                        to={{
                                                            pathname: "/manage-category",
                                                            category: category,
                                                            requestFor:"update"
                                                        }}
                                                        >
                                                        <img 
                                                            src={category.category_image !== '' ? category.category_image : default_img} 
                                                            alt=""
                                                        />
                                                        <h3>{category.service_category_title}</h3>
                                                        </Link>
                                                </div>
                                            ))
                                        )
                                    }
                                </div>                                
                            </div>
                            {/* <div className="Foot_btn_grievance"> */}
                            <Link className="btn btn-theme mb-5" 
                            to={{
                                pathname: '/manage-category',
                                user_data: this.state.user_data,
                                requestFor:"add"                                
                              }}
                            >
                                {GetLocalizationString('add_new')} <img src={ArrowRight} alt="" />
                            </Link>
                            {/* </div> */}
                        </div>
                    </section>
                </>
            ) : null
        );
    }
}

export default MyCategory;
