import React, { Component } from 'react'
import Category from "./Category"
import ArrowLeft from "./../../assets/images/al.png"
import { GetLocalizationString, HidePreloader, SetDefaultLanguage } from '../../Helpers/CustomHelper'
import CartIcon from "./../../assets/images/cart.png";
import SearchImg from "./../../assets/images/se.png"
import PersonIcon from "./../../assets/images/person.png"
import HomeService from "../../services/HomeService";
// import { Link } from "react-router-dom";
class SelectSpecialization extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            service_categories: [],
            category_info:{},
            cart_items: 0,
            search:'',
            parentId:0
        };
        this.RedirectToProfile = this.RedirectToProfile.bind(this);
        this.RedirectToCart = this.RedirectToCart.bind(this);
    }
    
    RedirectToProfile = () => {
        this.props.history.push({
            pathname: '/my-profile',
        });
    }    
    
    getHeadingTitle = () => {
        
        if(this.state.category_info.category_type == 3){
            return GetLocalizationString('select_category');
        } else {
            return GetLocalizationString('select_specialization');
        }
    }

    RedirectToCart = () => {
        this.props.history.push({
            pathname: '/my-cart',
        });
      }

      searchProducts = () => {        
        this.props.history.push({
            pathname: '/search-products',            
            search: '?search=' + this.state.search,
            parentId: this.state.parentId
        });
      }

      changeHandler = (event) => {
        const value = event.target.value;
    
        // Update state for search text
        this.setState({ [event.target.name]: value });
    
        // Clear any previous timeout
        clearTimeout(this.typingTimeout);
    
        // Set a new timeout for debounce
        this.typingTimeout = setTimeout(() => {
            // Call the API method after typing stops
            this.searchProducts();
        }, 1000); // 500ms debounce delay
    };

    componentDidMount() {
        HidePreloader()
        SetDefaultLanguage()
        this.getCartItems()
        let routeState        
        var parentId= 0;        
        if(this.props.location.service_categories){
            localStorage.setItem('specializationrouteState', JSON.stringify(this.props.location.service_categories))            
            routeState = this.props.location.service_categories
            let ServiceCategoriesList = routeState;            
            if(ServiceCategoriesList.length > 0){
                ServiceCategoriesList = ServiceCategoriesList.map(function(category, i){
                    parentId = category.level1_service_category_id;
                    return <Category key={i} actionFor={category.category_type == 3 ? 'product-list' : 'vendor-list'} category_info={category} nextPage="/my-profile" category_type='Service'/>
                })
                setTimeout(() => {
                    this.setState({
                        service_categories:ServiceCategoriesList,
                        parentId:parentId
                    })
                }, 100);
                
            }
            if(this.props.location.category_info){
                localStorage.setItem('sub_category_info', JSON.stringify(this.props.location.category_info))
            }
            this.setState({
                category_info:this.props.location.category_info,
            })
            localStorage.setItem('SelectedSubCategory',(localStorage.getItem('RequestFor') == 'Update') ? this.props.location.category_info.level2_service_category_id : 0)
            localStorage.setItem('SelectedSpecialization',0)
        } else {
            routeState = localStorage.getItem('specializationrouteState')
            if(routeState) routeState = JSON.parse(routeState)
            let ServiceCategoriesList = routeState;            
            if(ServiceCategoriesList.length > 0){                
                ServiceCategoriesList = ServiceCategoriesList.map(function(category, i){                    
                    parentId = category.level1_service_category_id;
                    return <Category key={i} actionFor={category.category_type == 3 ? 'product-list' : 'vendor-list'} category_info={category} nextPage="/my-profile" category_type='Service'/>
                })
                setTimeout(() => {
                    this.setState({
                        service_categories:ServiceCategoriesList,
                        parentId:parentId
                    })
                }, 100);
            }

            let category_info = localStorage.getItem('sub_category_info')
            if(category_info) category_info = JSON.parse(category_info)
            this.setState({
                category_info:category_info,
            })
            localStorage.setItem('SelectedSubCategory',(localStorage.getItem('RequestFor') == 'Update') ? category_info.level2_service_category_id : 0)
            localStorage.setItem('SelectedSpecialization',0)
        }
        
    }
    getCartItems = () => {
              HomeService.getCartItems()
                .then(response => {
                  HidePreloader();        
                  if ((global.successStatus).includes(response.status)) {
                    const cartItems = response.data.cart_items.total_items ?? 0;
                    this.setState({
                      cart_items: cartItems,            
                    });          
                  } else {        
                      // ShowNotifications('error', GetLocalizationString('not_found'), 1500);
                  }
                })
                .catch(error => {
                  HidePreloader();
                  // console.error('Error fetching cart items:', error);
                  // ShowNotifications('error', GetLocalizationString('not_found'), 1500);
                });
            }

    render() {
        let cart_items = this.state.cart_items;
        return (
        <>
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                        <a onClick={() => { this.props.history.goBack(); }}><img src={ArrowLeft} alt="" /></a>
                        </div>
                        <div className="col-6">
                            <h1 className="head_heading">
                            {
                                (localStorage.getItem('RequestFor') == 'Update')?
                                GetLocalizationString('select_specialization')
                                :
                                this.state.category_info.service_category_title ?? GetLocalizationString('select_specialization')
                            }
                            </h1>
                        </div>
                        <div className="col-2 text-right  pr-0">
                            <a  onClick={this.RedirectToProfile}><img src={PersonIcon} alt=""/></a>
                        </div>
                        {
                            cart_items > 0 &&
                            <div className="col-2 text-right">                            
                            <a onClick={this.RedirectToCart}><img src={CartIcon} alt="" className="bell_icon" />
                                {(cart_items > 0)
                                    ?
                                    <span className="bell_count">{cart_items}</span>
                                    :
                                    ''
                                }

                            </a>
                        </div>
                    }
                    </div>
                </div>
            </header>
            <section>
                <div className="container">
                    <div className="row">
                    {
                                (this.state.category_info.category_type == 3)
                                ?
                                (<div className="input-group">
                                    <div className="input-group-append">
                                    <button className="btn btn-under_input" type="button">
                                        <img src={SearchImg} alt="" className="img-fluid" />
                                    </button>
                                    </div>
                                    <input type="text" className="form-control new_bdr" placeholder={GetLocalizationString('search')} aria-label="" aria-describedby="basic-addon1" name='search' value={this.state.search} onChange={this.changeHandler}/>                                    
                                </div>)
                                :
                                ''
                     }
                        {
                            (localStorage.getItem('RequestFor') == 'Update')?
                            (<div className="col-12">
                                <h2 className="special_text">{GetLocalizationString('select_specialization_service')}</h2>
                            </div>)
                            :
                            (<div className="col-12">
                                <h2 className="special_text">{this.getHeadingTitle()}</h2>
                            </div>)
                        }
                        {this.state.service_categories}
                    </div>
                </div>
            </section>
        </>
        )
    }
}

export default SelectSpecialization
