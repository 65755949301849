import React from 'react';
import ArrowLeft from "./../assets/images/al.png";
import HomeService from '../services/HomeService';
import { GetLocalizationString, HidePreloader, ShowNotifications, SetDefaultLanguage, ShowPreloader } from './../Helpers/CustomHelper';
import dft_category_icon from './../assets/images/icon/dft_category_icon.png';
class ManageProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slug: '',
            product_name: '',
            unit_type: '',
            parent_category_id: '',
            price: '',
            total_qty: '',
            limit_alert: '',
            status_id: 1,
            image_file: null,
            isLoaded: false,
            errors: {},
            isSubmit: false,
            categories: [],
            unit_type_list: [],
            user_data: this.props.location.user_data ? this.props.location.user_data  : JSON.parse(localStorage.getItem('CurrentUser')),
            isUpdate: false,
            product_image: '',
            previous_image_file: '',
            status_list:[
                {id:1,name:"Active"},
                {id:2,name:"Inactive"}
            ]
        };
    }

    componentDidMount() {
        SetDefaultLanguage();
        ShowPreloader();
        if(this.props.location.user_data){
            localStorage.setItem('CurrentUser', JSON.stringify(this.props.location.user_data))
        }
        this.getCategories();
        // this.getUnitType();
        let routeState
        let requestFor
        if(this.props.location.requestFor) {
            requestFor = this.props.location.requestFor;
            localStorage.setItem('requestFor', this.props.location.requestFor);
        } else {
            requestFor = localStorage.getItem('requestFor');
        }
        localStorage.setItem('requestFor', this.props.location.requestFor);
        if (this.props.location.product) {
            localStorage.setItem('routeState', JSON.stringify(this.props.location.product))
            routeState = this.props.location.product;
            let product = routeState;            
            this.setState({
                slug: product.slug,
                product_name: product.product_name,
                unit_type: product.unit_type,
                parent_category_id: product.parent_id,
                price: product.price,
                total_qty: product.available_qty,
                limit_alert: product.limit_alert,
                status_id: product.status_id,
                isLoaded: true,
                isUpdate: true,
                product_image: product.product_image,
                previous_image_file: product.previous_image_file,
            });
        } else {             
            if(requestFor === 'update'){                
                routeState = JSON.parse(localStorage.getItem('routeState'));
                let product = routeState;
                this.setState({
                    slug: product.slug,
                    product_name: product.product_name,
                    unit_type: product.unit_type,
                    parent_category_id: product.parent_id,
                    price: product.price,
                    total_qty: product.available_qty,
                    limit_alert: product.limit_alert,
                    status_id: product.status_id,
                    isLoaded: true,
                    isUpdate: true,
                    product_image: product.product_image,
                    previous_image_file: product.previous_image_file,
                });    
            } else {
                this.setState({
                    isLoaded: true
                });
            }
        }
        HidePreloader();
    }

    getCategories = () => {
        ShowPreloader();
        const { user_data } = this.state;
        let params = {
            level2_service_category_id: user_data.level2_service_category_id
        };
        HomeService.getCategories(params)
            .then((res) => {
                console.log(res,"res1");
                if ((global.successStatus).includes(res.status)) {
                    HidePreloader();                    
                    this.setState({
                        categories: res.data.shopping_categories,
                        isLoaded: true,
                        loading: false
                    });
                } else {
                    HidePreloader();
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
                }
            }).catch((errors) => {
                HidePreloader();
                console.error(errors);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
            });
    }

    getUnitType = () => {
        ShowPreloader();        
        HomeService.getUnitType({})
            .then((res) => {
                console.log(res,"res111");
                if ((global.successStatus).includes(res.status)) {
                    HidePreloader();                    
                    this.setState({
                        unit_type_list: res.data,
                        isLoaded: true,
                        loading: false
                    });
                } else {
                    HidePreloader();
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
                }
            }).catch((errors) => {
                HidePreloader();
                console.error(errors);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
            });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleFileChange = (e) => {
        this.setState({ image_file: e.target.files[0] });
    };

    validateForm = () => {
        const { product_name, unit_type, parent_category_id, price, total_qty, image_file } = this.state;
        let errors = {};
        let formIsValid = true;

        // Check all required fields
        if (!product_name) {
            formIsValid = false;
            errors['product_name'] = 'Product name is required';
        }
        if (!parent_category_id) {
            formIsValid = false;
            errors['parent_category_id'] = 'Product category is required';
        }
        if (!unit_type) {
            formIsValid = false;
            errors['unit_type'] = 'Unit type is required';
        }
        if (unit_type.length > 10) {
            formIsValid = false;
            errors['unit_type'] = 'Unit type must be 10 charactes long.Please enter a valid unit type';
        }
        if (!price) {
            formIsValid = false;
            errors['price'] = 'Price is required';
        }
        if (!total_qty) {
            formIsValid = false;
            errors['total_qty'] = 'Total quantity is required';
        }
        // If this is an add operation (no slug), image file is required
        if (!this.state.slug && !image_file) {
            formIsValid = false;
            errors['image_file'] = 'Product image is required';
        }

        this.setState({ errors });
        return formIsValid;
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validateForm()) {
            ShowNotifications('error', GetLocalizationString('validation_error'), 1500);
            return;
        }

        ShowPreloader();
        const { product_name, unit_type, parent_category_id, price, total_qty, limit_alert, image_file,status_id } = this.state;
        const formData = new FormData();

        formData.append("product_name", product_name);
        formData.append("unit_type", unit_type);
        formData.append("parent_category_id", parent_category_id);
        formData.append("price", price);
        formData.append("total_qty", total_qty);
        formData.append("limit_alert", limit_alert);
        formData.append("status_id", status_id);
        if (image_file) formData.append("image_file", image_file);
        this.state.isUpdate && formData.append("slug", this.state.slug);
        this.state.isUpdate && formData.append("previous_image_file", this.state.previous_image_file);

        HomeService.ManageProduct(formData)
            .then(response => {
                HidePreloader();
                if ((global.successStatus).includes(response.status)) {
                    ShowNotifications('success', GetLocalizationString('product_saved'), 1500);
                    this.props.history.goBack();
                } else {
                    ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
                }
            })
            .catch(error => {
                HidePreloader();
                console.error('Error saving product:', error);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
            });
    };

    changeHandler = (event) => {
        let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        this.setState({ [event.target.name]: value });
        this.validator.showMessageFor(event.target.name);
    }

    render() {
        const { product_name, unit_type, parent_category_id, price, total_qty, limit_alert, isLoaded, errors,categories, status_id,status_list } = this.state;
        console.log(this.state.user_data,"user_data");
        return (
            isLoaded ? (
                <>
                    <header>
                        <div className="container">
                            <div className="row">
                                <div className="col-2">
                                    <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                                </div>
                                <div className="col-8">
                                    <h1 className="head_heading">{GetLocalizationString(this.state.isUpdate ? 'update_product' : 'add_product')}</h1>
                                </div>
                            </div>
                        </div>
                    </header>

                    <section className="signup_page pt-0">
                        <div className="container">
                            <div className="row">
                                {/* Image Upload */}
                                <div className="col-12 text-center">
                                    <div className="avatar-upload">
                                        <div className="avatar-edit">
                                            <input
                                                type='file'
                                                id="image_file"
                                                accept=".png, .jpg, .jpeg"
                                                onChange={this.handleFileChange}
                                            />
                                            <label htmlFor="image_file"></label>
                                        </div>
                                        <div className="avatar-preview">
                                            <div id="imagePreview" style={{ backgroundImage: `url(${this.state.image_file ? URL.createObjectURL(this.state.image_file) : ( this.state.isUpdate ? this.state.product_image : dft_category_icon)})` }}>
                                            </div>
                                        </div>
                                        {errors.image_file && <div className="error">{errors.image_file}</div>}
                                    </div>
                                </div>

                                {/* Form Fields */}
                                <div className="col-12">
                                    <form onSubmit={this.handleSubmit}>                                        
                                        <div className="form-group">
                                            <label htmlFor="parent_category_id">{GetLocalizationString('product_category')}</label>
                                            <select
                                                name="parent_category_id"
                                                className={`form-control ${errors.parent_category_id ? 'is-invalid' : ''}`}
                                                value={parent_category_id}
                                                onChange={this.handleInputChange}
                                                required
                                            >
                                                <option value="">Select a category</option>
                                                {categories.map(category => (
                                                    <option key={category.level2_service_category_id} value={category.level2_service_category_id}>
                                                        {category.service_category_title}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.parent_category_id && <div className="invalid-feedback">{errors.parent_category_id}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label>{GetLocalizationString('product_name')}</label>
                                            <input
                                                type="text"
                                                name="product_name"
                                                className={`form-control ${errors.product_name ? 'is-invalid' : ''}`}
                                                value={product_name}
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                            {errors.product_name && <div className="error">{errors.product_name}</div>}
                                        </div>                                        
                                        <div className="form-group">
                                            <label htmlFor="parent_category_id">{GetLocalizationString('product_unit_type')}</label>
                                            <input
                                                type="text"
                                                name="unit_type"
                                                max={10}
                                                className={`form-control ${errors.unit_type ? 'is-invalid' : ''}`}
                                                value={unit_type}
                                                placeholder='Ex: Kg, Ltr, Pcs, 10kg, 20kg, 1/2 kg, etc.'
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                            {errors.unit_type && <div className="invalid-feedback">{errors.unit_type}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label>{GetLocalizationString('unit_price')}</label>
                                            <input
                                                type="number"
                                                name="price"
                                                className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                                                value={price}
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                            {errors.price && <div className="error">{errors.price}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label>{GetLocalizationString('quantity')}</label>
                                            <input
                                                type="number"
                                                name="total_qty"
                                                className={`form-control ${errors.total_qty ? 'is-invalid' : ''}`}
                                                value={total_qty}
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                            {errors.total_qty && <div className="error">{errors.total_qty}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label>{GetLocalizationString('limit_alert')}</label>
                                            <input
                                                type="number"
                                                name="limit_alert"
                                                className="form-control"
                                                value={limit_alert}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>{GetLocalizationString('status')}</label>                                            
                                            <select
                                                name="status_id"
                                                className={`form-control`}
                                                value={status_id}
                                                onChange={this.handleInputChange}                                                
                                            >                                                
                                                {status_list.map(status_list => (
                                                    <option key={status_list.id} value={status_list.id}>
                                                        {status_list.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <button className="btn-theme mt-4 mb-3" type="submit">
                                            {GetLocalizationString(this.state.isUpdate ? 'update' : 'save')}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            ) : null
        );
    }
}

export default ManageProduct;
