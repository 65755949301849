import React, { Component } from 'react';
import ArrowLeft from "./../assets/images/al.png";
import ArrowRight from "./../assets/images/ar.png";
import default_prd_icon from "./../assets/images/icon/dft_category_icon.png";
import HomeService from '../services/HomeService';
import "./../assets/css/order.css";
import { GetLocalizationString, HidePreloader, SetDefaultLanguage, ShowPreloader } from './../Helpers/CustomHelper';
import { Link } from 'react-router-dom';
import Moment from "moment";

export class MyOrder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orders: [],
            pending_orders: [],
            in_progress_orders: [],
            completed_orders: [],
            isLoaded: false,
            activeTab: 'pending',
            page: 1,
            per_page: process.env.REACT_APP_PER_PAGE,
            orderStatus: 1,
            order_counts: 0,
            no_order_text:""
        }
        this.handleScroll = this.debounce(this.handleScroll.bind(this), 200);  // Debounce the scroll handler
    }

    componentDidMount() {
        ShowPreloader();
        SetDefaultLanguage();        
        this.getOrders();  // Fetch orders when the component mounts
        window.addEventListener("scroll", this.handleScroll); /** attaching scroll event listener */      
    }

    getOrders = () => {
        ShowPreloader();
        var params = {
            "order_status": this.state.orderStatus,
            "per_page": this.state.per_page,
            "page": this.state.page,
        }
        HomeService.GetMyOrders(params)
            .then((res) => {
                console.log(res,"res");
                if ((global.successStatus).includes(res.status)) {
                    HidePreloader();
                    if(res.data.orders.length === 0 && this.state.page === 1){
                        this.setState({no_order_text: GetLocalizationString('no_orders')})
                    } else{
                        this.setState({no_order_text: ""})
                    }
                    this.setState({
                        orders: [...this.state.orders,...res.data.orders],
                        order_counts: res.data.order_counts,
                        isLoaded: true,
                        loading: false
                    });
                } else {
                    HidePreloader();
                    this.setState({no_order_text: GetLocalizationString('no_orders'),isLoaded: true,loading: false})
                    console.log(res);
                }
            }).catch((errors) => {
                HidePreloader();
                this.setState({no_order_text: GetLocalizationString('no_orders'),isLoaded: true,loading: false})
                console.error(errors);
            });
    }

    // Function to change active tab
    setActiveTab = async (tab,status) => {
        await this.setState({ activeTab: tab, orderStatus: status,page:1,orders:[] });        
        this.getOrders();  // Fetch orders when the component mounts
    }
    formatOrderDate = (date) => {
        const orderDate = Moment(date);
    
        if (orderDate.isSame(Moment(), 'day')) {
            return `Today at ${orderDate.format('hh:mm A')}`;
        } else {
            return orderDate.format('DD MMM [at] hh:mm A');
        }
    };

    debounce(func, delay) {
        let debounceTimer;
        return function (...args) {
            const context = this;
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(context, args), delay);
        };
    }

    handleScroll = () => {
        const userScrollHeight = window.innerHeight + window.scrollY;
        const windowBottomHeight = document.documentElement.offsetHeight;
        const totalPages = Math.ceil(this.state.order_counts / this.state.per_page);

        if (userScrollHeight >= windowBottomHeight && !this.state.loading && this.state.page < totalPages) {
            this.setState(
                prevState => ({
                    page: prevState.page + 1,
                    loading: true,
                }),
                () => this.getOrders(this.state.page)  // Fetch orders when the component mounts
            );
        }
    };

    getOrderStatus = (status) => {
        switch (status) {
            case 1:
                return 'Pending';
            case 2:
                return 'In Progress';
            case 3:
                return 'Completed';
            case 4:
                return 'Cancelled';
            default:
                return 'Pending';
        }
    }
    getOrderStatusClass = (status) => {
        switch (status) {
            case 1:
                return 'badge bg-warning';
            case 2:
                return 'badge bg-warning';
            case 3:
                return 'badge bg-success';
            case 4:
                return 'badge bg-danger';
            default:
                return 'badge bg-warning';
        }
    }

    // Render orders based on the active tab
    renderOrders = (orders) => {
        return orders.length > 0 ? (
            orders.map((order, i) => (
                <div key={i} className="doctor_details heath_detail box-multiple mb-3">
                    
                    <div className='d-flex'>
                        <p className=' h6 col-md-6 text-start text-muted'>{this.formatOrderDate(order.created_at)}</p>
                        {/* <p className='col-md-6 text-right'><span className={"dot " + (order.order_status == 3 ? "bg-success" : "bg-warning")}></span>{order.order_status === 1 ? 'Pending' : (order.order_status === 2 ? 'In Progress' : 'Completed')}</p> */}
                        <p className="col-md-6 text-right text-white"><span className={this.getOrderStatusClass(order.order_status)}> <span className="dot"> </span>{ this.getOrderStatus(order.order_status)}</span></p>
                    </div>
                    {/* <div className="w-100">
                            <span className='text-left'>{order.created_at}</span>
                            <span className='text-right'>{order.order_status === 1 ? 'Pending' : (order.order_status === 2 ? 'In Progress' : 'Completed')}</span>
                    </div> */}
                    <div className="" key={order.store_detail.slug}>
                        <div className="row g-0 align-items-center">
                            {/* Product Image */}
                            <div className="col-md-2 text-center">
                                <img
                                    src={order.store_detail.category_image || default_prd_icon}
                                    alt={order.store_detail.service_category_title}
                                    className="img-fluid img-listing"
                                />
                            </div>

                            {/* Product Details */}
                            <div className="col-md-8" style={{ borderLeft: "1px solid #D1D1D1", paddingLeft: "10px" }}>
                                <div className="card-body">
                                    <h2 className="card-title m-0" style={{ color: "#396EA0" }}>{order.store_detail.service_category_title}</h2>
                                    <p className='text-muted m-0'>Order No: {order.slug}</p>
                                    <p className='text-muted m-0'>Total Items: {order.total_items}</p>
                                    <p className="card-text m-0"><strong className='text-color'>Total: ₹{order.total_amount}</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <div className="order-actions">
                        <Link
                            className="btn btn-theme"
                            to={() => {
                                return {
                                    pathname: '/order-detail/' + order.slug,
                                    is_buyer: "true",
                                    booking_info:order
                                }
                                }}
                            >
                            {GetLocalizationString('view_order')} <img src={ArrowRight} alt="" />
                        </Link>
                    </div>
                </div>
            ))
        ) : (
            <div className="no-orders">
                <h5 className='text-center'>{this.state.no_order_text}</h5>
            </div>
        );
    }

    render() {
        const { orders, activeTab, isLoaded } = this.state;

        return (
            isLoaded ? (
                <>
                    <header>
                        <div className="container">
                            <div className="row">
                                <div className="col-2">
                                    <a onClick={() => { this.props.history.goBack() }}>
                                        <img src={ArrowLeft} alt="" />
                                    </a>
                                </div>
                                <div className="col-8">
                                    <h1 className="head_heading">{GetLocalizationString('my_orders')}</h1>
                                </div>
                            </div>
                        </div>
                    </header>

                    <section>
                        <div className="container">
                            <div className="order-tabs">
                                <button onClick={() => this.setActiveTab('pending',1)} className={activeTab === 'pending' ? 'active' : ''}>
                                    {GetLocalizationString('pending',1)}
                                </button>
                                <button onClick={() => this.setActiveTab('in_progress',2)} className={activeTab === 'in_progress' ? 'active' : ''}>
                                    {GetLocalizationString('in_progress',2)}
                                </button>
                                <button onClick={() => this.setActiveTab('completed',3)} className={activeTab === 'completed' ? 'active' : ''}>
                                    {GetLocalizationString('completed',3)}
                                </button>
                            </div>

                            <div className="order-list">
                                {activeTab === 'pending' && this.renderOrders(orders)}
                                {activeTab === 'in_progress' && this.renderOrders(orders)}
                                {activeTab === 'completed' && this.renderOrders(orders)}
                            </div>
                        </div>
                    </section>
                </>
            ) : (
                <div className="loading">
                    <h5>Loading...</h5>
                </div>
            )
        );
    }
}

export default MyOrder;
