import React, { Component } from 'react'
import HomeService from "./../../services/HomeService";
import AuthenticationService from "./../../services/AuthenticationService";
import { GetLocalizationString, getLoggedInUserId, HidePreloader, SetDefaultLanguage, ShowNotifications } from './../../Helpers/CustomHelper'
import Category from "./Category"
import ArrowLeft from "./../../assets/images/al.png"

class SelectCategories extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            service_categories: [],
            shopping_categories: [],
            category_info:{},
            upcomming_booking: {},
            user_data: {},
        };
      }

      getUserProfile(){
        AuthenticationService.getProfile()
        .then((res)=> {
            if((global.successStatus).includes(res.status)) {
                SetDefaultLanguage(res.data.selected_language)
                if(res.data.is_profile_completed == 1 && res.data.role_id == process.env.REACT_APP_PROVIDER_ROLE_ID){
                    this.props.history.push({
                        pathname: '/home'
                    });
                }
                this.setState({
                    user_data: res.data,
                    default_language: res.data.default_language,
                })
                localStorage.setItem("userData", res.data ? JSON.stringify(res.data) : {})
            }else{
                console.log(res) 
            }
        }).catch((errors)=>{
            console.error(errors)
        })
      }

      componentDidMount() {
        HidePreloader()
        this.getUserProfile();
        // this.setState({user_data: getLoggedInUser()});

        SetDefaultLanguage()
        localStorage.setItem('RequestFor','Update')
        console.log(this.props)
        HomeService.GetAllCategoriesList({user_id: getLoggedInUserId()})
        .then((res) =>{
          if((global.successStatus).includes(res.status)) {
            console.log(res.data)
            let ServiceCategoriesList = res.data.service_categories.data;            
            let ShoppingCategoriesList = res.data.shopping_categories.data;            
            let upcomming_booking = res.data.upcomming_appointment;
            if(ServiceCategoriesList.length > 0){
                ServiceCategoriesList = ServiceCategoriesList.map(function(category, i){
                    if(category.level2_service_category_id == process.env.REACT_APP_EMERGENCY_CATEGORY_ID){
                        return null
                    }else{
                        return <Category key={i} actionFor="select-sub-categories" category_info={category} nextPage="/select-sub-category" category_type='Service'/>
                    }
                })
                ShoppingCategoriesList = ShoppingCategoriesList.map(function(category, i){
                    if(category.level2_service_category_id == process.env.REACT_APP_EMERGENCY_CATEGORY_ID){
                        return null
                    }else{
                        return <Category key={i} actionFor="select-sub-categories" category_info={category} nextPage="/select-sub-category" category_type='Service'/>
                    }
                })
                this.setState({
                    service_categories:ServiceCategoriesList,
                    shopping_categories:ShoppingCategoriesList,
                    upcomming_booking
                })
    
                console.log(this.state.upcomming_booking)
            }
            
          }else if(res.status === 401){
              console.log(res);
              ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
          }else if(res.status === 400){
              console.log(res);
              ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
          }
          else{
              console.log(res);
              ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
          }
        }).catch((errors)=>{
            console.log(errors);
        });
      }
    
    render() {
        const {user_data} = this.state;
        
        return (
        <>
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                        <a onClick={() => { this.props.history.goBack('/home'); }}><img src={ArrowLeft} alt="" /></a>
                        </div>
                        <div className="col-8">
                            <h1 className="head_heading">{GetLocalizationString('select_category')}</h1>
                        </div>
                        <div className="col-2 text-right">
                        
                        </div>
                    </div>
                </div>
            </header>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="special_text">{GetLocalizationString('select_category_in_which_you_provide_services')}</p>
                        </div>
                        {this.state.service_categories}
                        {user_data.is_profile_completed != 0 &&
                        this.state.shopping_categories}
                    </div>
                </div>
            </section>
        </>
        )
    }
}

export default SelectCategories
