////////////////////////////////////////////////////////////
//     							                          //
//  Program: UpdateProfile.jsx                            //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import ArrowLeft from "./../../assets/images/al.png"
import default_user_img from "./../../assets/images/icon/ic_user_image.png"
import booking_img from "./../../assets/images/icon/booking.png"
import delete_img from "./../../assets/images/icon/delete.png"
import suggestion_img from "./../../assets/images/icon/suggestion.png"
import account_img from "./../../assets/images/icon/user.png"
import review_img from "./../../assets/images/icon/satisfaction.png"
import logout_img from "./../../assets/images/icon/shutdown.png"
import AuthenticationService from '../../services/AuthenticationService'
import { Link } from 'react-router-dom'
import patient_booking_img from "./../../assets/images/icon/patient_booking.png"
import my_product_img from "./../../assets/images/icon/my-product.png"
import my_order_img from "./../../assets/images/icon/my_order.png"
import my_recevived_order_img from "./../../assets/images/icon/recevived_order.png"
import my_category_img from "./../../assets/images/icon/my-category.png"
import { ShowPreloader,HidePreloader, GetLocalizationString, SetDefaultLanguage } from '../../Helpers/CustomHelper'
import Swal from 'sweetalert2';
export class MyProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            isLoaded: false,
            user_detail: {},
            service_category: {},
            default_language: localStorage.getItem('default-user-lang') ?? process.env.REACT_APP_DEFAULT_LANGUAGE
        }
        this.DeletteMyAccount = this.DeletteMyAccount.bind(this)
    }

    DeletteMyAccount = (e) => {
        e.preventDefault()
        Swal.fire({
            title: GetLocalizationString('confirmation_title'),
            html: GetLocalizationString('confirmation_text'),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonColor: 'red',
            denyButtonColor: '#999999',
            confirmButtonText: GetLocalizationString('delete_account'),
            denyButtonText: GetLocalizationString('cancel'),
        }).then((result) => {
            if (result.isConfirmed) {
                AuthenticationService.delete_account()
                .then((res)=> {
                    console.log(res)
                }).catch((errors)=>{
                    HidePreloader()
                    console.error(errors)
                })
            } else if (result.isDenied) {
                return false
            }
        })
    }
    componentDidMount() {
        SetDefaultLanguage(this.state.default_language)
        ShowPreloader()
        AuthenticationService.getProfile()
        .then((res)=> {
            console.log(res)
            if((global.successStatus).includes(res.status)) {
               //SetDefaultLanguage(res.data.default_language)
               HidePreloader()
               this.setState({
                   user_detail:res.data,
                   service_category:res.data.service_category ? res.data.service_category : {},
                   isLoaded:true,
                   //default_language: res.data.default_language
               })
            }else{
                HidePreloader()
                this.setState({
                    isLoaded:true
                })
                console.log(res) 
            }
        }).catch((errors)=>{
            HidePreloader()
            console.error(errors)
        })
                
    }
    render() {        
        return (
            <>
             <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                            <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                        </div>
                        <div className="col-8">
                            <h1 className="head_heading">{GetLocalizationString('profile_text')}</h1>
                        </div>
                        
                    </div>
                </div>
            </header>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="profile_list">
                                <div className="media" >
                                    <div className="media-body pt-2">
                                    { this.state.service_category.category_type == 3 ? <h5>{this.state.service_category.service_category_title}</h5> : '' }                                    
                                    <h6>{this.state.user_detail.full_name}</h6>
                                    <p>{this.state.user_detail.mobile}</p>
                                    </div>
                                    { this.state.service_category.category_type == 3 ? 
                                    <img src={this.state.service_category.category_image ?? default_user_img} alt=""/>
                                    :                                     
                                    <img src={this.state.user_detail.profile_image_url ?? default_user_img} alt=""/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="box_cat">
                        <Link to={`/my-bookings/`+this.state.user_detail.slug}>
                            <img src={booking_img} alt=""/>
                            <h3>{GetLocalizationString('my_booking')}</h3>
                        </Link>                        
                        </div>
                        { this.state.service_category.category_type == 3 ? '' : 
                        <div className="box_cat">
                            <Link to={`/my-orders/`+this.state.user_detail.slug}>
                                <img src={my_order_img} alt=""/>
                                <h3>{GetLocalizationString('my_order')}</h3>
                            </Link>
                        </div>
                        }
                        {
                            (this.state.user_detail.role_id == 2 && this.state.user_detail.level1_service_category_id == process.env.REACT_APP_DOCTOR_CATEGORY_ID)
                            ?
                            (<div className="box_cat">
                            <Link to={`/patient-bookings/`+this.state.user_detail.slug}>
                                <img src={patient_booking_img} alt=""/>
                                <h3>{GetLocalizationString('patient_booking')}</h3>
                            </Link>
                        </div>)
                        :
                        ''
                        }
                        {
                            (this.state.user_detail.role_id == 2 && this.state.user_detail.service_category.category_type == 3)
                            ?
                            (
                        <>                        
                        <div className="box_cat">
                            <Link to={`/my-received-order/`+this.state.user_detail.slug}>
                                <img src={my_recevived_order_img} alt=""/>
                                <h3>{GetLocalizationString('received_order')}</h3>
                            </Link>
                        </div>
                        <div className="box_cat">
                        <Link to={`/my-product/`+this.state.user_detail.slug}>
                            <img src={my_product_img} alt=""/>
                            <h3>{GetLocalizationString('my_product')}</h3>
                        </Link>
                    </div>
                    <div className="box_cat">
                        <Link to={`/my-categories/`+this.state.user_detail.slug}>
                            <img src={my_category_img} alt=""/>
                            <h3>{GetLocalizationString('my_category')}</h3>
                        </Link>
                    </div>
                    </>
                        )
                        :
                        ''
                        }
                        <div className="box_cat">
                            <Link to={`/update-profile`}>
                                <img src={account_img} alt=""/>
                                <h3>{GetLocalizationString('my_account')}</h3>
                            </Link>
                        </div>
                        <div className="box_cat">
                            <Link to={`/my-reviews/`+this.state.user_detail.slug}>
                                <img src={review_img} alt=""/>
                                <h3>{GetLocalizationString('review')}</h3>
                            </Link>
                        </div>
                        
                        <div className="box_cat">
                            <Link to={`/suggestions`}>
                                <img src={suggestion_img} alt=""/>
                                <h3>{GetLocalizationString('suggestion')}</h3>
                            </Link>
                        </div>
                        
                        <div className="box_cat">
                            <Link to={`/logout`}>
                                <img src={logout_img} alt=""/>
                                <h3>{GetLocalizationString('logout')}</h3>
                            </Link>
                        </div>

                        <div className="box_cat">
                            <Link onClick={this.DeletteMyAccount}>
                                <img src={delete_img} alt=""/>
                                <h3>{GetLocalizationString('delete_account')}</h3>
                            </Link>
                        </div>

                        
                        
                    </div>
                </div>
            </section>
    
            </>
          )
    }
}

export default MyProfile