////////////////////////////////////////////////////////////
//     							                          //
//  Program: RegisterSuccess.jsx                          //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import Done from "./../assets/images/icon/done.png"
import ArrowRight from "./../assets/images/ar.png"
import { GetLocalizationString, HidePreloader, SetDefaultLanguage} from '../Helpers/CustomHelper'
import { Link } from 'react-router-dom'
export class OrderConfirmation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            provider_info:{}
            
        };
    }

    componentDidMount() {
        localStorage.removeItem('cart_items');
        HidePreloader()
        SetDefaultLanguage()        
    }

    render() {
        return (
            <section>
            <div className="container">
              <div className="row">
                <div className="col-12 ">
                  <div className="success_box ">
                    <div className="col-12 text-center">
                      <img src={Done} alt="" className="img-fluid"/>
                      <p className="text-sucess">{GetLocalizationString('order_success_message')}</p>
                    </div>
        
                    <Link onClick={()=>{
                        this.props.history.push({
                            pathname: '/home'
                        });
                    }} className="btn btn-theme mb-5">{GetLocalizationString('go_to_home')} <img src={ArrowRight} alt=""/></Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
    }
}

export default OrderConfirmation