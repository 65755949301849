import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ArrowRight from "./../assets/images/ar.png";
import ArrowLeft from "./../assets/images/al.png";
import CartIcon from "./../assets/images/cart.png";
import HomeService from './../services/HomeService';
import { ShowPreloader, HidePreloader, ShowNotifications, GetLocalizationString } from './../Helpers/CustomHelper';
import default_prd_icon from "./../assets/images/icon/my-product.png";

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProductsList: [],
      cart_items: [],
      full_name: '',
      phone_no: '',
      address: '',
      remark: '',
      errors: {},
      isSubmit: false,
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.createOrderPayload = this.createOrderPayload.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    ShowPreloader();
    const cartItemsFromProps = this.props.location.cart_items || JSON.parse(localStorage.getItem('cart_items')) || [];
    this.setState({
      ProductsList: cartItemsFromProps,
      cart_items: cartItemsFromProps.map(item => ({
        product_id: item.product_id,
        quantity: item.quantity,
      })),
      isLoaded: true
    });
    localStorage.setItem('cart_items', JSON.stringify(cartItemsFromProps));  // Save cart items to localStorage
    HidePreloader();
  }

  // Handle input change and update state
  changeHandler(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  // Function to validate form inputs
  validateForm() {
    const { full_name, phone_no, address } = this.state;
    let errors = {};
    let formIsValid = true;

    if (!full_name) {
      formIsValid = false;
      errors['full_name'] = 'Full Name is required';
    }
    if (!phone_no) {
      formIsValid = false;
      errors['phone_no'] = 'Phone Number is required';
    } else if (!/^\d{10}$/.test(phone_no)) {
      formIsValid = false;
      errors['phone_no'] = 'Phone Number must be 10 digits';
    }
    if (!address) {
      formIsValid = false;
      errors['address'] = 'Address is required';
    }

    this.setState({ errors });
    return formIsValid;
  }

  // Function to create order payload
  createOrderPayload() {
    const { full_name, phone_no, address, remark, cart_items } = this.state;

    const payload = {
      full_name,
      phone_no,
      address,
      remark,
      cart_items
    };

    return payload;
  }

  // Handle form submission and call server API
  handleSubmit(e) {
    e.preventDefault();
    ShowPreloader();

    // Validate form inputs
    if (this.validateForm()) {
      this.setState({ isSubmit: true });

      // Create the order payload
      const orderPayload = this.createOrderPayload();

      // Call the PlaceOrder service
      HomeService.PlaceOrder(orderPayload)
        .then((res) => {
          HidePreloader();
          if ((global.successStatus).includes(res.status)) {
            console.log(res);
            this.props.history.push({
              pathname: '/order-confirmation',
            });
          } else {
            console.log(res);
            ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
          }
        })
        .catch((errors) => {
          HidePreloader();
          console.error(errors);
          ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
        });
    } else {
      HidePreloader();
      ShowNotifications('error', GetLocalizationString('validation_error'), 1500);
    }
  }

  render() {
    const { ProductsList, full_name, phone_no, address, remark, errors } = this.state;

    return (
      <>
        <header>
          <div className="container">
            <div className="row">
              <div className="col-2">
                <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
              </div>
              <div className="col-8">
                <h1 className="head_heading">{GetLocalizationString('checkout')}</h1>
              </div>
              <div className="col-2 text-right">
                <Link
                  to={{
                    pathname: '/my-cart-items/' + this.props.match.params.slug,
                    category_info: this.props.history.location.category_info ?? {},
                    parent_category_id: this.props.history.location.category_info ? this.props.history.location.category_info.level1_service_category_id : 0
                  }}>
                  <img src={CartIcon} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </header>

        <section>
          <div className="container">
            <div className="row">
              {ProductsList.map((product, i) => (
                <div className="col-12 mb-3" key={product.cart_id}>
                  <div className="product-item d-flex align-items-center p-3  doctor_details heath_detail box-multiple">
                    <div className="product-image">
                      <img className="img-fluid" src={product.product_image == "" ? default_prd_icon : product.product_image } alt={product.product_name} style={{ width: "60px", height: "60px" }} />
                    </div>
                    <div className="product-details flex-grow-1 mx-3" style={{ borderLeft: "1px solid #D1D1D1", paddingLeft: "10px" }}>
                      <h5 className="m-0">{product.product_name}</h5>
                      <p className="text-muted m-0">{product.unit_type} x {product.quantity}</p>
                      <p className="text-muted m-0 h5">₹{product.price}</p>
                    </div>
                  </div>
                </div>
              ))}

              {/* Form for Customer Details */}
              <div className="col-12">
                <div className="form-group">
                  <label>Full Name*</label>
                  <input
                    type="text"
                    name="full_name"
                    className={`form-control ${errors.full_name ? 'is-invalid' : ''}`}
                    value={full_name}
                    onChange={this.changeHandler}
                  />
                  {errors.full_name && <div className="invalid-feedback">{errors.full_name}</div>}
                </div>

                <div className="form-group">
                  <label>Phone Number*</label>
                  <input
                    type="text"
                    name="phone_no"
                    className={`form-control ${errors.phone_no ? 'is-invalid' : ''}`}
                    value={phone_no}
                    onChange={this.changeHandler}
                  />
                  {errors.phone_no && <div className="invalid-feedback">{errors.phone_no}</div>}
                </div>

                <div className="form-group">
                  <label>Address*</label>
                  <input
                    type="text"
                    name="address"
                    className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                    value={address}
                    onChange={this.changeHandler}
                  />
                  {errors.address && <div className="invalid-feedback">{errors.address}</div>}
                </div>

                <div className="form-group">
                  <label>Remarks</label>
                  <textarea
                    name="remark"
                    className={`form-control ${errors.remark ? 'is-invalid' : ''}`}
                    value={remark}
                    onChange={this.changeHandler}
                  />
                  {errors.remark && <div className="invalid-feedback">{errors.remark}</div>}
                </div>
              </div>

              {/* Place Order Button */}
              <div className="col-12 text-center mt-5">
                <button className="btn btn-theme btn-book_suss" onClick={this.handleSubmit}>
                  {GetLocalizationString('place_order')} <img src={ArrowRight} alt="" />
                </button>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Checkout;
