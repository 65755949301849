import React, { Component } from 'react';
import ArrowLeft from "./../assets/images/al.png";
import ArrowRight from "./../assets/images/ar.png";
import whatsaap from "./../assets/images/social/whatsapp_new.png";
import call from "./../assets/images/social/call_new.png";
import HomeService from "./../services/HomeService";
import { GetLocalizationString, HidePreloader, SetDefaultLanguage, ShowPreloader, ShowNotifications } from './../Helpers/CustomHelper';
import Moment from 'moment';
import default_user_img from "./../assets/images/default_user_img.png"
import default_prd_icon from "./../assets/images/icon/my-product.png";
import PhonePe from "./../assets/images/payment/phonepe.png"
import GooglePay from "./../assets/images/payment/Gpay.png"
import Bhimupi from "./../assets/images/payment/bhim.png"
import Paytm from "./../assets/images/payment/paytm.png"
import Upload from "./../assets/images/upload.png"
import $ from 'jquery'
import { Link } from 'react-router-dom';

export class OrderDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderDetail: null,
            isLoading: true,
            isBuyer: true,
            orderSlug: this.props.match.params.slug,
            file: Upload,
            is_uploaded: false,
            file_input: null,
        };
        this.ClickFileUpload = this.ClickFileUpload.bind(this)
        this.SingleFileUpload = this.SingleFileUpload.bind(this)
        this.RemoveProfileImage = this.RemoveProfileImage.bind(this)
        this.isValidFileUploaded = this.isValidFileUploaded.bind(this)
        this.UploadPaymentReceipt = this.UploadPaymentReceipt.bind(this)
    }

    ClickFileUpload() {
        $('#payment_screenshot').click()
    }

    isValidFileUploaded = (file) => {
        const validExtensions = ['png', 'jpeg', 'jpg']
        const fileExtension = file.type.split('/')[1]
        return validExtensions.includes(fileExtension)
    }

    componentDidMount() {
        ShowPreloader();
        SetDefaultLanguage();
        let routeState
        let isBuyer
        if (this.props.location.booking_info) {
            localStorage.setItem('routeState', JSON.stringify(this.props.location.booking_info))
            localStorage.setItem('isBuyer', this.props.location.is_buyer)
            routeState = this.props.location.booking_info
            isBuyer = this.props.location.is_buyer
            let booking_info = routeState;
            let is_buyer = isBuyer == "true" ? true : false;
            setTimeout(() => {
                this.setState({
                    isBuyer: is_buyer,
                    booking_info: booking_info,
                    isLoading: false
                })
            }, 100);

        } else {
            routeState = localStorage.getItem('routeState')
            isBuyer = localStorage.getItem('isBuyer')
            if (routeState) routeState = JSON.parse(routeState)
            let booking_info = routeState;
            let is_buyer = isBuyer == "true" ? true : false;
            setTimeout(() => {
                this.setState({
                    isBuyer: is_buyer,
                    booking_info: booking_info,
                    isLoading: false
                })
            }, 100);
        }
        console.log(isBuyer, "is buyer");
        // Fetch order details
        const slug = this.props.match.params.slug; // Assuming the slug comes from URL params        
        this.getOrderDetails(slug);
    }

    getOrderDetails(slug) {
        var params = {
            "slug": slug,
        }

        HomeService.GetOrderDetails(params, slug)
            .then((res) => {
                console.log(res, "order detail");
                if (res.status === 200) {
                    this.setState({
                        orderDetail: res.data,
                        isLoading: false,
                    });
                }
                HidePreloader();
                this.setState({ isLoading: false });
            })
            .catch((err) => {
                HidePreloader();
                console.error(err);
                this.setState({ isLoading: false });
            });
    }

    orderStatus = (status) => {
        switch (status) {
            case 2: return "In Progress";
            case 3: return "Completed";
            case 4: return "Cancelled";
            default: return "Pending";
        }
    }

    ChangeOrderStatus = (status, orderSlug) => {
        var params = {
            "order_status": status,
        }

        HomeService.UpdateOrderStatus(params, orderSlug)
            .then((res) => {
                console.log(res, "assa");
                if (res.status === 200) {
                    ShowNotifications('success', res.message, 1500);
                } else if (res.status === 404) {
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('not_found'), 1500)
                } else if (res.status === 400) {
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('appointment_could_not_cancle'), 1500)
                } else {
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500)
                }
                HidePreloader();
                this.getOrderDetails(orderSlug);
            })
            .catch((err) => {
                HidePreloader();
                console.error(err);
            });
    }

    SingleFileUpload = (event) => {
        if (event.target.files[0] !== undefined) {
            var file = event.target.files[0]
            if (!this.isValidFileUploaded(file)) {
                ShowNotifications('warning', GetLocalizationString('only_image_allowed'), 1500)
                this.setState({
                    file: Upload,
                    file_input: null
                })
            } else {
                this.setState({
                    file: URL.createObjectURL(event.target.files[0]),
                    file_input: event.target.files[0],
                    is_uploaded: true
                })
            }
        } else {
            this.setState({
                file: Upload,
                file_input: null
            })
        }

    };
    RemoveProfileImage = () => {
        this.setState({
            file: Upload,
            file_input: null,
            is_uploaded: false
        })
    }
    UploadPaymentReceipt = () => {
        var orderSlug = this.state.orderDetail.slug;
        var formData = new FormData();
        formData.append("image_file", this.state.file_input)
        formData.append("slug", orderSlug)
        console.log(formData, "Formdata");
        HomeService.UploadPaymentReceipt(formData)
            .then((res) => {
                console.log(res, "assa");
                if (res.status === 200) {
                    ShowNotifications('success', res.message, 1500);
                } else if (res.status === 404) {
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('not_found'), 1500)
                } else if (res.status === 400) {
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('appointment_could_not_cancle'), 1500)
                } else {
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500)
                }
                HidePreloader();
                this.getOrderDetails(orderSlug);
            })
            .catch((err) => {
                HidePreloader();
                console.error(err);
            });
    }
    getMessage = (status) => {
        if (status == 3) {
            return GetLocalizationString('order_completed_note')
        } else if (status == 2) {
            return GetLocalizationString('order_in_progress_note')
        } else {
            return GetLocalizationString('order_pending_note')
        }

    }


    render() {
        const { isLoading, orderDetail, isBuyer } = this.state;
        console.log(isBuyer, "order detail state");
        return (
            !isLoading && orderDetail ? (
                <>
                    <header>
                        <div className="container">
                            <div className="row">
                                <div className="col-2">
                                    <a onClick={() => { this.props.history.goBack() }}>
                                        <img src={ArrowLeft} alt="" />
                                    </a>
                                </div>
                                <div className="col-8">
                                    <h1 className="head_heading">{GetLocalizationString('order_details')}</h1>
                                </div>
                            </div>
                        </div>
                    </header>
                    <section className='heath_detail'>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="user_book_info user_detail box_sd_none mt-0 p-0" >
                                        <div className="media" >
                                            <div className="media-body">
                                                <h5 className="p-0">{isBuyer ? orderDetail.seller.service_category?.service_category_title : orderDetail.buyer?.full_name}  <span className='service_title'>{isBuyer ? "Seller" : "Customer"}</span></h5>
                                                <div className="contact_details">
                                                    <ul>
                                                        {
                                                            isBuyer ? (
                                                                <>
                                                                    <li><a href={`tel:${orderDetail.seller?.mobile}`}><img src={call} alt="" className="icon_syml_call" /> {orderDetail.seller?.mobile}</a></li>

                                                                    {
                                                                        orderDetail.seller.whatsapp_number != '' && orderDetail.seller.whatsapp_number != null
                                                                            ?
                                                                            <li><a href={`https://wa.me/${orderDetail.seller?.whatsapp_number}`}><img src={whatsaap} alt="" className="icon_syml" /> {orderDetail.seller?.whatsapp_number}</a></li>
                                                                            :
                                                                            ''
                                                                    }
                                                                </>
                                                            )
                                                                :
                                                                (
                                                                    <>
                                                                        <li><a href={`tel:${orderDetail.buyer?.mobile}`}><img src={call} alt="" className="icon_syml_call" /> {orderDetail.buyer?.mobile}</a></li>

                                                                        {
                                                                            orderDetail.buyer.whatsapp_number != '' && orderDetail.buyer.whatsapp_number != null
                                                                                ?
                                                                                <li><a href={`https://wa.me/${orderDetail.buyer?.whatsapp_number}`}><img src={whatsaap} alt="" className="icon_syml" /> {orderDetail.buyer?.whatsapp_number}</a></li>
                                                                                :
                                                                                ''
                                                                        }
                                                                    </>
                                                                )
                                                        }

                                                    </ul>
                                                </div>
                                            </div>
                                            {isBuyer ? (
                                                <img className="d-flex" src={orderDetail.seller.service_category?.category_image ?? default_user_img} alt="" />
                                            ) : (
                                                <img className="d-flex" src={orderDetail.buyer?.profile_image_url ?? default_user_img} alt="" />
                                            )
                                        }
                                        </div>
                                        <hr />                                        
                                        {
                                            orderDetail.order_items.map((item, i) => {
                                                return (<div className="doctor_details heath_detail box-multiple" key={"products" + i}>
                                                    <div className="mb-3">
                                                        <div className="row g-0 align-items-center">
                                                            {/* Product Image */}
                                                            <div className="col-md-2 text-center">
                                                                <img
                                                                    src={item.product.product_image || default_prd_icon}
                                                                    alt={item.product.product_name}
                                                                    className="img-fluid img-listing"
                                                                />
                                                            </div>

                                                            {/* Product Details */}
                                                            <div className="col-md-8" style={{ borderLeft: "1px solid #D1D1D1", paddingLeft: "10px" }}>
                                                                <div className="card-body">
                                                                    <h2 className="card-title m-0" style={{ color: "#396EA0" }}>{item.product.product_name}</h2>
                                                                    <p className='text-muted m-0'>Quantity: {item.quantity} ({item.product.unit_type}) x ₹{item.unit_price}</p>
                                                                    <p className="card-text m-0 order-price"><strong className='text-color'>Total: ₹{item.total_price}</strong></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                            })
                                        }
                                        <div className="col-12 mb-2 mt-2">
                                            <h3 className="card-text m-0 text-right" style={{ color: "#396EA0" }}><strong>Total: ₹{parseFloat(orderDetail.total_amount).toFixed(2)}</strong></h3>
                                        </div>
                                        {isBuyer && (
                                            <h5 className="mt-2 text-center" style={{ color: "#396EA0" }}>{this.getMessage(orderDetail.order_status)}</h5>
                                        )}
                                        <div className="book_info_date border-0 pl-0 pr-0">
                                            <p>{GetLocalizationString('order_no')} <span>{orderDetail.slug}</span> </p>
                                            <p>{GetLocalizationString('order_date')} <span>{Moment(orderDetail.created_at).format('dddd, DD MMMM')}</span> </p>
                                            <p>{GetLocalizationString('order_time')}<span>{Moment(orderDetail.created_at).format('hh:mm A')}</span> </p>
                                            <p>{GetLocalizationString('price')}<span className='card-text m-0'>₹{orderDetail.total_amount}</span> </p>
                                            <p>{GetLocalizationString('Address')}<span>{orderDetail.address}</span> </p>
                                            <p>{GetLocalizationString('remark')}<span>{orderDetail.remark}</span> </p>
                                            {(orderDetail.is_payment_done == 1)
                                                ? (
                                                    <p>{GetLocalizationString('payment')} <span>{(orderDetail.total_amount > 0) ? '₹ ' + orderDetail.total_amount : '₹ 0.00'}</span> </p>
                                                )
                                                :
                                                ''}
                                            <p>{GetLocalizationString('status')}<span>{this.orderStatus(orderDetail.order_status)}</span> </p>

                                            {(orderDetail.is_payment_done == 1 || orderDetail.order_status == 3)
                                                ? (
                                                    <div className="col-12">
                                                        <div className="payment_proof">
                                                            <h3>{GetLocalizationString('payment_proof')}</h3>
                                                            {orderDetail.proof_of_payment != "" ?
                                                                <Link target='_blank' href={(orderDetail.proof_of_payment) ?? '#'}><img src={(orderDetail.proof_of_payment) ?? ''} alt={orderDetail.proof_of_payment} style={{ maxWidth: "150px" }} /></Link>
                                                                :
                                                                'Payment Receipt not available.'
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                                :
                                                (   isBuyer && orderDetail.order_status != 4 && (
                                                    <>
                                                        <h4 className='m-0'>{GetLocalizationString('payment_option_txt')}</h4>
                                                        <p className='pay_info_text'>{orderDetail.spacial_fees_instruction ?? ''}</p>
                                                        <div className="row">
                                                            <div className="col-12 ">

                                                                {
                                                                    orderDetail.seller.payment_instructions.map((payment, i) => {
                                                                        var default_img = (i == 0) ? Paytm : (i == 1) ? PhonePe : (i == 2) ? GooglePay : Bhimupi
                                                                        var default_txt = (i == 0) ? GetLocalizationString('paytm') : (i == 1) ? GetLocalizationString('phonepe') : (i == 2) ? GetLocalizationString('googlepay') : GetLocalizationString('bhimupi')
                                                                        if (payment.account_number != '') {
                                                                            return (<div className="pay_type" key={i}>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <div className="media">
                                                                                            <img className="d-flex" src={default_img} alt="" />
                                                                                            <div className="media-body">
                                                                                                <h5 className="m-0 pr-0 Pay_mode">{default_txt}<span className="float-right pay_mobile">{payment.account_number}</span></h5>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>)
                                                                        } else {
                                                                            return ''
                                                                        }
                                                                    })
                                                                }
                                                                <div className="fee_charge mt-3">
                                                                    <div className="form-group ">
                                                                        <label htmlFor="" className="d-block mb-3">{GetLocalizationString('attachment_proof')}</label>
                                                                        <img src={this.state.file} alt="" onClick={this.ClickFileUpload} style={UploadScreenShot} />

                                                                        {(this.state.is_uploaded) ? (<b className='close_btn_cust' onClick={this.RemoveProfileImage}><i className='fa fa-close'></i></b>) : ''}

                                                                        <input type='file' id='payment_screenshot' style={FileUploadStyle} onChange={this.SingleFileUpload} />
                                                                    </div>

                                                                </div>
                                                                <button className="btn-theme mt-4 mb-3" type="submit" onClick={() => this.UploadPaymentReceipt()}> Submit <img src={ArrowRight} alt="" /> </button>
                                                            </div>
                                                        </div>
                                                    </> 
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                { !isBuyer &&
                                <>
                                    <div className="row col-12 m-0 p-0 mb-5">
                                        {orderDetail.order_status == 1 &&
                                        <>
                                            <a onClick={() => { this.ChangeOrderStatus(4, orderDetail.slug) }} className="btn btn_sec_theme bg-danger w-100 mb-1">{GetLocalizationString('mark_as_cancel')}</a>
                                            <a onClick={() => { this.ChangeOrderStatus(2, orderDetail.slug) }} className="btn btn_sec_theme w-100">{GetLocalizationString('mark_as_in_progress')}</a>
                                        </>
                                        }
                                        {orderDetail.order_status == 2 &&
                                            <a onClick={() => { this.ChangeOrderStatus(3, orderDetail.slug) }} className="btn btn_sec_theme w-100">{GetLocalizationString('mark_as_completed')}</a>
                                        }
                                    </div>
                                </>
                                }
                                {
                                    isBuyer && orderDetail.order_status == 1 &&
                                    <>
                                        <div className="row col-12 m-0 p-0 mb-5">
                                            <a onClick={() => { this.ChangeOrderStatus(4, orderDetail.slug) }} className="btn btn_sec_theme bg-danger w-100">{GetLocalizationString('mark_as_cancel')}</a>
                                        </div>                                    
                                    </>
                                }
                            </div>
                        </div>
                    </section>
                </>
            ) : (
                <>
                    {
                        isLoading ? (
                            <div>Loading...</div>
                        ) : (
                            <>
                                <header>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-2">
                                                <a onClick={() => { this.props.history.goBack() }}>
                                                    <img src={ArrowLeft} alt="" />
                                                </a>
                                            </div>
                                            <div className="col-8">
                                                <h1 className="head_heading">{GetLocalizationString('order_details')}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                <section className='heath_detail'>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <h3>{GetLocalizationString('no_order_details_found')}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </>
                        )
                    }
                </>
            )
        );
    }
}
const FileUploadStyle = {
    display: 'none'
}
const UploadScreenShot = {
    height: '60px',
    width: '60px'
}
export default OrderDetail;
