import React from 'react';
import ArrowLeft from "./../assets/images/al.png";
import HomeService from '../services/HomeService';
import { GetLocalizationString, HidePreloader, ShowNotifications, SetDefaultLanguage, ShowPreloader } from './../Helpers/CustomHelper';
import dft_category_icon from './../assets/images/icon/dft_category_icon.png';
class ManageCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            service_category_title: '',
            service_category_title_hi: '',
            unit_type: '',
            parent_category_id: '',
            price: '',
            total_qty: '',
            limit_alert: '',
            image_file: null,
            isLoaded: false,
            errors: {},
            isSubmit: false,
            categories: [],
            unit_type_list: [],
            user_data: this.props.location.user_data ? this.props.location.user_data  : JSON.parse(localStorage.getItem('CurrentUser')),
            isUpdate: false,
            previous_image_file: '',
            category_image: '',
        };
    }

    componentDidMount() {
        SetDefaultLanguage();
        ShowPreloader();
        if(this.props.location.user_data){
            localStorage.setItem('CurrentUser', JSON.stringify(this.props.location.user_data))
        }
        let routeState        
        let requestFor
        if(this.props.location.requestFor) {
            requestFor = this.props.location.requestFor;
            localStorage.setItem('requestFor', this.props.location.requestFor);
        } else {
            requestFor = localStorage.getItem('requestFor');
        }
        if (this.props.location.category) {
            localStorage.setItem('routeState', JSON.stringify(this.props.location.category))
            routeState = this.props.location.category
            let category = routeState;
            this.setState({
                slug: category.slug,
                level2_service_category_id: category.level2_service_category_id,
                service_category_title: category.service_category_title,
                service_category_title_hi: JSON.parse(category.service_category_title_hi),
                parent_category_id: category.level1_service_category_id,                
                isLoaded: true,
                isUpdate: true,
                category_image: category.category_image,
                previous_image_file: category.previous_image_file,
            });
        } else {
            if(requestFor === 'update'){       
                routeState = JSON.parse(localStorage.getItem('routeState'))
                let category = routeState;
                this.setState({
                    slug: category.slug,
                    level2_service_category_id: category.level2_service_category_id,
                    service_category_title: category.service_category_title,
                    service_category_title_hi: JSON.parse(category.service_category_title_hi),
                    parent_category_id: category.level1_service_category_id,                
                    isLoaded: true,
                    isUpdate: true,
                    category_image: category.category_image,
                    previous_image_file: category.previous_image_file,
                });            
            } else {
                this.setState({
                    isLoaded: true
                });
            }
        }
        HidePreloader();
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleFileChange = (e) => {
        this.setState({ image_file: e.target.files[0] });
    };

    validateForm = () => {
        const { service_category_title, image_file } = this.state;
        let errors = {};
        let formIsValid = true;

        // Check all required fields
        if (!service_category_title) {
            formIsValid = false;
            errors['service_category_title'] = 'Category name is required';
        }        
        // If this is an add operation (no slug), image file is required
        if (!this.state.slug && !image_file) {
            formIsValid = false;
            errors['image_file'] = 'Product image is required';
        }
        this.setState({ errors });
        return formIsValid;
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validateForm()) {
            ShowNotifications('error', GetLocalizationString('validation_error'), 1500);
            return;
        }

        ShowPreloader();
        const { service_category_title,service_category_title_hi, parent_category_id, image_file,slug,previous_image_file } = this.state;
        const formData = new FormData();

        formData.append("service_category_title", service_category_title);
        formData.append("service_category_title_hi", service_category_title_hi);
        formData.append("category_type", 3);
        formData.append("parent_category_id", parent_category_id ? parent_category_id : this.state.user_data.level2_service_category_id);
        formData.append("status", 1);                
        formData.append("previous_image_file", previous_image_file);                
        if (image_file) formData.append("image_file", image_file);
        if(this.state.isUpdate){
            HomeService.UpdateCategory(formData, slug)
            .then(response => {
                HidePreloader();
                if ((global.successStatus).includes(response.status)) {
                    ShowNotifications('success', response.message, 1500);
                    this.props.history.goBack();
                } else {
                    ShowNotifications('error', response.message, 1500);
                }
            })
            .catch(error => {
                HidePreloader();
                console.error('Error saving product:', error);
                ShowNotifications('error', error.message, 1500);
            });
        } else {        
        HomeService.SaveCategory(formData)
            .then(response => {
                HidePreloader();
                if ((global.successStatus).includes(response.status)) {
                    ShowNotifications('success', response.message, 1500);
                    this.props.history.goBack();
                } else {
                    ShowNotifications('error', response.message, 1500);
                }
            })
            .catch(error => {
                HidePreloader();
                console.error('Error saving product:', error);
                ShowNotifications('error', error.message, 1500);
            });
        }
    };

    render() {
        const { service_category_title,service_category_title_hi, isLoaded, errors } = this.state;
        console.log(this.state.user_data,"user_data");
        return (
            isLoaded ? (
                <>
                    <header>
                        <div className="container">
                            <div className="row">
                                <div className="col-2">
                                    <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                                </div>
                                <div className="col-8">
                                    <h1 className="head_heading">{GetLocalizationString(this.state.isUpdate ? 'update_category' : 'add_category')}</h1>
                                </div>
                            </div>
                        </div>
                    </header>

                    <section className="signup_page pt-0">
                        <div className="container">
                            <div className="row">
                                {/* Image Upload */}
                                <div className="col-12 text-center">
                                    <div className="avatar-upload">
                                        <div className="avatar-edit">
                                            <input
                                                type='file'
                                                id="image_file"
                                                accept=".png, .jpg, .jpeg"
                                                onChange={this.handleFileChange}
                                            />
                                            <label htmlFor="image_file"></label>
                                        </div>
                                        <div className="avatar-preview">
                                            <div id="imagePreview" style={{ backgroundImage: `url(${this.state.image_file ? URL.createObjectURL(this.state.image_file) : ( this.state.isUpdate ? this.state.category_image : dft_category_icon)})` }}>
                                            </div>
                                        </div>
                                        {errors.image_file && <div className="error">{errors.image_file}</div>}
                                    </div>
                                </div>

                                {/* Form Fields */}
                                <div className="col-12">
                                    <form onSubmit={this.handleSubmit}>                                                                                
                                        <div className="form-group">
                                            <label>{GetLocalizationString('product_name')} (ENG)</label>
                                            <input
                                                type="text"
                                                name="service_category_title"
                                                className={`form-control ${errors.service_category_title ? 'is-invalid' : ''}`}
                                                value={service_category_title}
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                            {errors.service_category_title && <div className="error">{errors.service_category_title}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label className="hind">{GetLocalizationString('product_name')} (हिंदी)</label>
                                            <input
                                                type="text"
                                                name="service_category_title_hi"
                                                className={`form-control hind ${errors.service_category_title_hi ? 'is-invalid' : ''}`}
                                                value={ service_category_title_hi ? service_category_title_hi  : ''}
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                            {errors.service_category_title_hi && <div className="error">{errors.service_category_title_hi}</div>}
                                        </div>
                                        <button className="btn-theme mt-4 mb-3" type="submit">
                                            {GetLocalizationString(this.state.isUpdate ? 'update' : 'save')}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            ) : null
        );
    }
}

export default ManageCategory;
