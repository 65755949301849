////////////////////////////////////////////////////////////
//     							                          //
//  Program: UpdateProfile.jsx                            //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: Anil kumar Gupta 						  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import ArrowLeft from "./../assets/images/al.png"
import AuthenticationService from '../services/AuthenticationService'
import HomeService from '../services/HomeService'
import { isEmptyObject } from "jquery";
import { GetLocalizationString, HidePreloader, ShowNotifications, ShowPreloader } from './../Helpers/CustomHelper'

export class MyNotifications extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: {},
            errors: {},
            user_detail: {},
            notifications: [],
            total_notifications: 0,
            unread_notifications: 0,
            per_page: process.env.REACT_APP_PER_PAGE,
            page:1,
            loading:false,
            isLoaded:false
        }
        this.GetAllNotifications = this.GetAllNotifications.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
        this.RedirectToDetailPage = this.RedirectToDetailPage.bind(this)
        this.ReadAllNotifications = this.ReadAllNotifications.bind(this)
    }

    componentDidMount() {
        ShowPreloader()
        AuthenticationService.getProfile()
        .then((res)=> {
            if((global.successStatus).includes(res.status)) {
               this.setState({
                   user_detail:res.data
               })
            }else{
                console.log(res) 
            }
        }).catch((errors)=>{
            console.error(errors)
        })
        
        this.GetAllNotifications();
        window.addEventListener("scroll", this.handleScroll); /** attaching scroll event listener */   

            
    }
    RedirectToDetailPage = (notification) => {
        ShowPreloader()
        if(notification.is_read == 0){
            var params = {
                "type": 'read_single',
                "slug": notification.slug
            }
            
            HomeService.ReadNotifications(params)
            .then((res) =>{
                if((global.successStatus).includes(res.status)) {
                    HidePreloader()
                    this.setState({
                        isLoaded:true,
                        loading: false,
                    }) 
                    var booking = JSON.parse(notification.message_body)
                    if(!isEmptyObject(booking)){
                        if(notification.redirection_type == 'BookingSubmited' || notification.redirection_type == 'BookingAccepted'){
                            this.props.history.push({
                                pathname: '/booking-detail/provider',
                                booking_info: booking
                            });
                        }
                        if(notification.redirection_type == 'Shopping'){
                            console.log(booking,booking.user_id, this.state.user_detail.user_id,booking.sender_id == this.state.user_detail.user_id ? false : true)
                            this.props.history.push({
                                pathname: '/order-detail/'+ booking.slug,
                                booking_info: booking,
                                is_buyer:booking.user_id == this.state.user_detail.user_id ? "true" : "false"
                            });
                        }
                    }else{
                        window.location.reload()
                    }         
                }else if(res.status === 401){
                    HidePreloader()
                    this.setState({
                        isLoaded:true,
                    })
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
                }else if(res.status === 400){
                    HidePreloader()
                    this.setState({
                        isLoaded:true,
                    })
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
                }
                else{
                    HidePreloader()
                    this.setState({
                        isLoaded:true,
                    })
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
                }
            }).catch((errors)=>{
                HidePreloader()
                this.setState({
                    isLoaded:true,
                })
                console.log(errors);
            });
        }else{
            HidePreloader()
        }
    }

    ReadAllNotifications = () => {
        ShowPreloader()
        var params = {
            "type": 'read_all',
            "slug": ''
        }
        
        HomeService.ReadNotifications(params)
        .then((res) =>{
            if((global.successStatus).includes(res.status)) {
                window.location.reload()      
            }else{
                HidePreloader()
                this.setState({
                    isLoaded:true,
                })
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
        }).catch((errors)=>{
            HidePreloader()
            this.setState({
                isLoaded:true,
            })
            console.log(errors);
        });
        
    }

    handleScroll = () => {
        let userScrollHeight = window.innerHeight + window.scrollY;
        let windowBottomHeight = document.documentElement.offsetHeight;
        let totalPages_pre = (this.state.total_notifications / this.state.per_page)
        let totalPages =  (this.state.total_notifications % this.state.per_page) == 0 ? totalPages_pre : totalPages_pre + 1
        
        if (userScrollHeight >= windowBottomHeight) {
            var current_page = this.state.page+1
            if(current_page <= totalPages){
                this.setState({
                    page:current_page,
                    loading: true
                })
                this.GetAllNotifications()
            }
        }
    };

    GetAllNotifications(){
        var params = {
            "per_page": this.state.per_page,
            "page": this.state.page
        }
        
        HomeService.GetAllNotifications(params)
        .then((res) =>{
            if((global.successStatus).includes(res.status)) {
                HidePreloader()
                this.setState({
                    notifications:[...this.state.notifications,...res.data.notifications],
                    isLoaded:true,
                    loading: false,
                    total_notifications: res.data.total_notifications,
                    unread_notifications: res.data.unread_notifications
                })          
            }else{
                HidePreloader()
                this.setState({
                    isLoaded:true,
                })
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
        }).catch((errors)=>{
            HidePreloader()
            this.setState({
                isLoaded:true,
            })
            console.log(errors);
        });
    }
    render() {
        return (
            (this.state.isLoaded)
            ?
            <>
             <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                            <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                        </div>
                        <div className="col-8">
                            <h1 className="head_heading">{GetLocalizationString('notifications')}</h1>
                        </div>
                        
                    </div>
                </div>
            </header>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 mb-5">
                        {
                          (this.state.isLoaded && this.state.unread_notifications > 0)
                          ?
                          (<><p className='pull-right pr-1 read-all-btn' onClick={this.ReadAllNotifications}>{GetLocalizationString('read_all_notification')}</p><br/></>)
                          :
                          ''
                        }
                        {
                        (this.state.notifications.length <= 0 && this.state.isLoaded)
                        ?
                        
                        (
                            <div className="success_box no_cnt_data">
                                <h5 className="app_done">{GetLocalizationString('no_reviews_found')}</h5>
                            </div>
                        )
                        :
                        
                        this.state.notifications.map((notification, i) => {
                            return (
                                <a key={i} onClick={()=> {this.RedirectToDetailPage(notification)}} style={notification.is_read == 1 ? notification_muted : notification_unmuted }>
                                    <div className={notification.is_read == 1 ? "virtual_box" : "virtual_box active"}>
                                        <p>{notification.message_subject.replace(/['"]+/g, '')}</p>
                                    </div>
                                </a>                                            
                            ) 
                        })
                                                
                        }
                        </div>   
                    </div>
                </div>
            </section>

            {
            (this.state.loading == true)
            ?
            (
                <div style={loadingCSS}>
                    <center><b>Loading...</b></center>
                </div>
            )
            :
            ''
            }
    
            </>
            :
            ''
          )
    }
}
const loadingCSS = { 
    height: "50px", 
    margin: "30px" 
};
const notification_muted ={
    opacity: "1"
}
const notification_unmuted ={
    opacity: "1"
}
export default MyNotifications