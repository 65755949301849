import React from 'react';
import ArrowLeft from "./../assets/images/al.png"
import ArrowRight from "./../assets/images/ar.png"
// import default_user_img from "./../assets/images/default_user_img.png"
import AuthenticationService from '../services/AuthenticationService'
import HomeService from '../services/HomeService'
import { GetLocalizationString, HidePreloader,ShowNotifications, SetDefaultLanguage,  ShowPreloader } from './../Helpers/CustomHelper'
import { Link } from 'react-router-dom'

class MyProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input: {},
            errors: {},
            user_detail: {},
            products: [                
                    {
                        product_id: 1,
                        product_name: "Indian Royal Apple",
                        price: 240,
                        product_image: null,
                        sold: 15,
                        available_qty: 50,
                        unit: "Kg",
                    },
                    {
                        product_id: 2,
                        product_name: "Fresh Banana",
                        price: 80,
                        product_image: null,
                        sold: 10,
                        available_qty: 20,
                        unit: "Dozen",
                    },
                    {
                        product_id: 3,
                        product_name: "Organic Wheat",
                        price: 120,
                        product_image: null, // No image provided
                        sold: 25,
                        available_qty: 10,
                        unit: "Kg",
                    },
                    {
                        product_id: 4,
                        product_name: "Golden Rice",
                        price: 150,
                        product_image: null, // Replace with actual image URL
                        sold: 5,
                        available_qty: 5,
                        unit: "Kg",
                    },
                    {
                        product_id: 5,
                        product_name: "Fresh Milk",
                        price: 60,
                        product_image: null,
                        sold: 30,
                        available_qty: 0,
                        unit: "Ltr",
                    },
                
                ],
            products_count: 0,
            loading: false,
            error: null,
            page: 1,
            per_page: process.env.REACT_APP_PER_PAGE,
            default_language: localStorage.getItem('default-user-lang') ?? process.env.REACT_APP_DEFAULT_LANGUAGE,
            isLoaded: false,
            showModal: false,
            currentProduct: null
        };
        // this.handleScroll = this.handleScroll.bind(this)
    }

    componentDidMount() {
        SetDefaultLanguage();        
        ShowPreloader()
        SetDefaultLanguage()    
        AuthenticationService.getProfile()
        .then((res)=> {
            console.log(res)
            if((global.successStatus).includes(res.status)) {
               HidePreloader()
               this.setState({
                   user_detail:res.data,
                   isLoaded:true
               })
            }else{
                HidePreloader()
                this.setState({
                    isLoaded:true,
                })
                console.log(res) 
            }
        }).catch((errors)=>{
            HidePreloader()
            this.setState({
                isLoaded:true,
            })
            console.error(errors)
        })
    }
    
    handleScroll = () => {
        let userScrollHeight = window.innerHeight + window.scrollY;
        let windowBottomHeight = document.documentElement.offsetHeight;
        let totalPages_pre = (this.state.personal_bookings_count / this.state.per_page)
        let totalPages =  (this.state.personal_bookings_count % this.state.per_page) == 0 ? totalPages_pre : totalPages_pre + 1
        
        if (userScrollHeight >= windowBottomHeight) {
            var current_page = this.state.page+1
            if(current_page <= totalPages){
                this.setState({
                    page:current_page,
                    loading: true,
                    isLoaded:false
                })
                this.getResidentBookings(this.state.page)
            }
        }
    };

    getResidentBookings = () =>{
        ShowPreloader()
        var params = {
            "per_page": this.state.per_page,
            "page": this.state.page,
            "slug": this.props.match.params.slug,
            "type": 2,
        }
        HomeService.GetAllBookings(params)
        .then((res) =>{
            this.setState({
                loading: false
            })
            console.log(res)
            if((global.successStatus).includes(res.status)) {
                HidePreloader()
                this.setState({
                    personal_bookings:[...this.state.personal_bookings,...res.data.patient_bookings],
                    loading: false,
                    isLoaded:true,
                    personal_bookings_count: res.data.patient_bookings_count
                })
                console.log('personal_bookings ',res.data.patient_bookings);            
            }else if(res.status === 401){
                HidePreloader()
                this.setState({
                    isLoaded:true,
                })
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }else if(res.status === 400){
                HidePreloader()
                this.setState({
                    isLoaded:true,
                })
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
            else{
                HidePreloader()
                this.setState({
                    isLoaded:true,
                })
                console.log(res);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'),1500)
            }
        }).catch((errors)=>{
            HidePreloader()
            this.setState({
                isLoaded:true,
            })
            console.log(errors);
        });
    }

    openModal = (product) => {
        alert('Edit Product');
        this.setState({ showModal: true, currentProduct: product });
    };

    closeModal = () => {
        this.setState({ showModal: false, currentProduct: null });
    };

    handleEdit = (e) => {
        e.preventDefault();
        // Handle the editing logic here (e.g., update the product details)
        this.closeModal();
    };

    render() {        

        return (
            (this.state.isLoaded)
            ?
            <>
             <header>
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                            <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                        </div>
                        <div className="col-8">
                            <h1 className="head_heading">{GetLocalizationString('my_product')}</h1>
                        </div>
                        
                    </div>
                </div>
            </header>

            <section>
                <div className="container">
                    <div className="row">
                         
                       
                        <div className="col-12 product-list">
                        {
                        (this.state.products.length <= 0 && this.state.isLoaded)
                        ?
                        
                        (
                            <div className="success_box no_cnt_data">
                                <h5 className="app_done">{GetLocalizationString('no_product')}</h5>
                            </div>
                        )
                        :
                        this.state.products.map((product) => (
                            <div className="product-card" key={product.product_id}>
                                <div className="product-image">
                                    {product.product_image ? (
                                        <img src={product.product_image} alt={product.product_name} />
                                    ) : (
                                        <span className="placeholder">🍏</span> // Placeholder icon for products without an image
                                    )}
                                </div>
                                <div className="product-details">
                                    <h2>{product.product_name}</h2>
                                    <p>₹{product.price} ({product.unit})</p>
                                    <p>Sold: {product.sold} {product.unit}</p>
                                    <p>Available Qty: {product.available_qty} {product.unit}</p>
                                    {product.available_qty < 10 && <span className="warning">Only {product.available_qty} left</span>}
                                </div>
                                <button className="edit-button" onClick={() => this.openModal(product)}>✏️</button>
                            </div>
                        ))
                        
                        }
                        
                        {
                        (this.state.loading == true)
                        ?
                        (
                            <div style={loadingCSS}>
                                <center><b>Loading...</b></center>
                            </div>
                        )
                        :
                        ''
                        }
                        
                
                       </div>
                       {this.state.showModal && this.state.currentProduct && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={this.closeModal}>&times;</span>
                            <h2>Edit Product</h2>
                            <form onSubmit={this.handleEdit}>
                                <div>
                                    <label>Product Name:</label>
                                    <input type="text" defaultValue={this.state.currentProduct.product_name} required />
                                </div>
                                <div>
                                    <label>Price:</label>
                                    <input type="number" defaultValue={this.state.currentProduct.price} required />
                                </div>
                                <div>
                                    <label>Available Qty:</label>
                                    <input type="number" defaultValue={this.state.currentProduct.available_qty} required />
                                </div>
                                <button type="submit">Save Changes</button>
                            </form>
                        </div>
                    </div>
                )}
                    </div>
                    {/* <div className="col-8 offset-2"> */}
                                    <Link className="btn btn-theme mb-5"
                                        to={() => {
                                            return {
                                            pathname: '/add-product'
                                            }
                                        }}> {GetLocalizationString('add_new')} <img src={ArrowRight} alt=""/></Link>
                    {/* </div>                     */}
                </div>
            </section>
    
            </>
            :
            ''
          )
    }
}

const loadingCSS = { 
    height: "50px", 
    margin: "30px" 
};

export default MyProduct;
