////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Category.jsx                                 //
//  Application: Dashboard                                //
//  Option: to view Category                              //
//  Developer: Anil kumar Gupta  						              //
//  Date: 2022-05-25                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import default_img from "./../../images/cat.png"

export class Category extends Component {
  constructor(props) {
    super(props)
    this.state = {
        error: null,
        isLoaded: false,
        nextPage: '',
        classes:'',
        actionFor: this.props.actionFor,
        category_slug: this.props.category_info.slug ?? '',
        category_id: this.props.category_info.level2_service_category_id ?? 0
    };
    this.RedirectToSubCategories = this.RedirectToSubCategories.bind(this);
  }

  RedirectToSubCategories (elmt) {
    var actionFor = this.props.actionFor
    
    switch (actionFor) {
      case 'select-sub-categories':
        localStorage.setItem('RequestFor','Update')
        this.setState({ nextPage: '/select-sub-category' })
        break;
      case 'sub-categories-list':
        localStorage.setItem('RequestFor','View')
        this.setState({ nextPage: '/select-sub-category' })
        break;
      case 'vendor-list':
        localStorage.setItem('SelectedSpecialization',(localStorage.getItem('RequestFor') == 'Update') ? this.state.category_id : 0)
        break;
      case 'product-list':
        localStorage.setItem('SelectedSpecialization',(localStorage.getItem('RequestFor') == 'Update') ? this.state.category_id : 0)
        break;
      case 'specialization-list':
        if ((this.state.category_slug != process.env.REACT_APP_DOCTOR_CATEGORY_SLUG)) {
          if ((localStorage.getItem('RequestFor') == 'Update')) {
            localStorage.setItem('SelectedSpecialization',(localStorage.getItem('RequestFor') == 'Update') ? this.state.category_id : 0)
          }          
        }
        break;
        
      default:
        break;
    }
  }
  
  componentDidMount(){
    if ((localStorage.getItem('RequestFor') == 'Update')) {
      if(this.state.actionFor === 'vendor-list'){
        this.setState({
          classes: 'box_cat box_cat_new box_cat_two'
        })
      }else{
        this.setState({
          classes: 'box_cat box_cat_new'
        })
      }
      
    }else{
      if(this.state.actionFor === 'vendor-list'){
        this.setState({
          classes: 'box_cat box_cat_two'
        })
      }else{
        this.setState({
          classes: 'box_cat'
        })
      }
      
    }
  }

  // Helper function to generate pathname
generatePathname = () => {
  const { actionFor, category_slug } = this.state;
  const { category_info } = this.props;
console.log(category_info,"category_info");
  if (actionFor === 'select-sub-categories') {
    return '/select-sub-category';
  }

  if (actionFor === 'product-list') {
    return `/products/${category_slug}`;
  }

  if (actionFor === 'sub-categories-list') {
    return '/select-sub-category';
  }

  if (actionFor === 'specialization-list') {
    return category_info.child_service_categories.length > 0
      ? '/select-specialization'
      : '';
  }

  if (actionFor === 'vendor-list') {
    if (localStorage.getItem('RequestFor') === 'Update') {
      return '/update-profile';
    }

    return category_info.level1_service_category_id != process.env.REACT_APP_TRANSPOTATION_ID
      ? `/get-providers-list/${category_slug}`
      : `/get-providers-list/${category_slug}`;
  }

  // Default pathname if none of the conditions match
  return '';
};

  render() {
    const { category_info } = this.props;    
    return (
      <>
      
        <div className={this.state.classes}>
            {
              (this.props.category_type == 'Grievance') ? (
                <Link  
                  to={() =>{
                      return {
                        pathname: '/send-grievance-report/'+this.props.category_info.slug , 
                        category_info: this.props.category_info
                      }
                  }}
                >
                  <img src={(this.props.category_info.category_image !== '') ? this.props.category_info.category_image : default_img } alt=""/>
                  <h3>{this.props.category_info.grievance_category_title}</h3>
                  
                </Link>
              ) : (<>
                {console.log(this.state.actionFor,"ss1",this.state.actionFor === 'product-list')}
                {/* <Link onClick={() => this.RedirectToSubCategories(this.props.category_info.slug)} 
                  to={() =>{
                      return {
                        pathname: (this.state.actionFor === 'select-sub-categories') ?
                        '/select-sub-category' 
                        :(this.state.actionFor === 'sub-categories-list') ?
                        '/select-sub-category'
                        :(this.state.actionFor === 'specialization-list') ?
                        (this.props.category_info.child_service_categories.length > 0) ? '/select-specialization'                        
                        :(this.state.actionFor === 'vendor-list') ?
                        (localStorage.getItem('RequestFor') == 'Update') ? 
                        '/update-profile'
                        :(this.props.category_info.level1_service_category_id != process.env.REACT_APP_TRANSPOTATION_ID) ? '/get-providers-list/'+this.state.category_slug : '/get-providers-list/'+this.state.category_slug
                        :(localStorage.getItem('RequestFor') == 'Update') ? 
                        '/update-profile'
                        :(this.props.category_info.level1_service_category_id != process.env.REACT_APP_TRANSPOTATION_ID) ? '/get-providers-list/'+this.state.category_slug : '/get-providers-list/'+this.state.category_slug
                        :(localStorage.getItem('RequestFor') == 'Update') ? 
                        '/update-profile'
                        :(this.props.category_info.level1_service_category_id != process.env.REACT_APP_TRANSPOTATION_ID) ? '/get-providers-list/'+this.state.category_slug :  '/get-providers-list/'+this.state.category_slug,
                        service_categories: this.props.category_info.child_service_categories,
                        category_info: this.props.category_info
                      }
                  }}
              
                > */}
                <Link 
      onClick={() => this.RedirectToSubCategories(category_info.slug)} 
      to={{
        pathname: this.generatePathname(),
        service_categories: category_info.child_service_categories,
        category_info: category_info
      }}
    >
      <img 
        src={category_info.category_image !== '' ? category_info.category_image : default_img} 
        alt=""
      />
      <h3>{category_info.service_category_title}</h3>
    </Link>
                  {/* <img src={(this.props.category_info.category_image !== '') ? this.props.category_info.category_image : default_img } alt=""/>
                  <h3>{this.props.category_info.service_category_title}</h3>
                </Link> */}
                </>
              )
            }
            
        </div>
      </>
    );
  }
}

export default Category;
